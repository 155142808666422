import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import BiLingualTable, {
  IBiLingualData,
  rendrerFn,
} from 'components/ui/BiLingualTable/BiLingualTable';
import styles from './DataPrivacy.module.sass';

const columns: ColumnsType<IBiLingualData> = [
  {
    key: 'german',
    title: 'GREENDatenschutzhinweise Tendergy',
    dataIndex: 'german',
    render: rendrerFn,
  },
  {
    key: 'targetLanguage',
    title: 'Tendergy Data Privacy Policy',
    dataIndex: 'targetLanguage',
    render: rendrerFn,
  },
];

const privacyData: IBiLingualData[] = [
  {
    german: 'Verantwortlicher:',
    targetLanguage: 'Entity responsible:',
    bold: true,
  },
  {
    german: 'Schneider Electric GmbH',
    targetLanguage: 'Schneider Electric GmbH',
  },
  {
    german: 'EUREF-Campus 12-13,',
    targetLanguage: 'EUREF-Campus 12-13,',
  },
  {
    german: 'Torgauer Straße 12-15,',
    targetLanguage: 'Torgauer Straße 12-15,',
  },
  {
    german: '10829 Berlin',
    targetLanguage: '10829 Berlin',
  },
  {
    german: 'Deutschland',
    targetLanguage: 'Germany',
  },
  {
    german: 'Telefon: +49 30 23 88 84 - 240',
    targetLanguage: 'Telephone: +49 30 23 88 84 – 240',
  },
  {
    german: 'E-Mail: info@inno2fleet.com',
    targetLanguage: 'Email: info@inno2fleet.com',
  },
  {
    german:
      'Wir nehmen den Schutz Ihrer persönlichen Daten sehr ernst. So behandeln wir Ihre personenbezogenen Daten selbstverständlich stets entsprechend der gesetzlichen Datenschutzvorschriften. Wir haben einen fachkundigen und zuverlässigen externen Datenschutzbeauftragten bestellt.',
    targetLanguage:
      'We take the protection of your personal data very seriously. This means that we will of course always treat your personal data in accordance with the statutory data protection regulations. We have appointed a specialist and reliable external data protection officer.',
  },
  {
    german:
      'Die externe Datenschutzbeauftragung wird von der UIMC Dr. Voßbein GmbH & Co KG wahrgenommen (<a href="https://www.uimc.de/datenschutz">https://www.uimc.de/datenschutz</a>), mit Dr. Jörn Voßbein als extern bestelltem Datenschutzbeauftragten.',
    targetLanguage:
      'External data protection supervision is carried out by UIMC Dr. Voßbein GmbH & Co KG (<a href="https://www.uimc.de/datenschutz">https://www.uimc.de/datenschutz</a>), with Dr Jörn Voßbein as the externally appointed Data Protection Officer.',
  },
  {
    german:
      'Nachfolgend möchten wir Sie über die Verarbeitung von personenbezogenen Daten informieren.',
    targetLanguage: 'We would like to make you aware of the processing of personal data below.',
  },
  {
    german: '1. Ihre Registrierung als Kunde/Endverbraucher',
    targetLanguage: '1. Your registration as a customer/end user',
    subSection: true,
  },
  {
    german: 'Erhebung und Verarbeitung persönlicher Daten',
    targetLanguage: 'Collection and processing of personal data',
  },
  {
    german: 'Anlage eines Nutzeraccounts',
    targetLanguage: 'Creating a user account',
    bold: true,
  },
  {
    german:
      'Wir verarbeiten im Rahmen Ihrer ersten Anmeldung und unserer Kontaktaufnahme mit Ihnen Ihre E-Mail-Adresse, Ihren Vor- und Zunamen, ggf. auch Ihren Titel sowie Ihre Telefonnummer. Wir legen für Sie danach einen Nutzeraccount im Rahmen eines Vertragsverhältnisses gemäß Art. 6 Abs 1 lit b DSGVO an.',
    targetLanguage:
      'As part of your initial registration and our contact with you, we process your email address, your first and last name, your title if applicable, and your telephone number. We will then create a user account for you as part of a contractual relationship in accordance with Article 6(1)(b) of the GDPR.',
  },
  {
    german: 'Angebotsanfrage/-einholung',
    targetLanguage: 'Request for quote/Procurement',
    bold: true,
  },
  {
    german:
      'Im Rahmen der Angebotsvorbereitung, Angebotslegung und Vermittlung des Produktkaufs bzw. der Produktinstallation werden außerdem noch erforderliche Angaben erfragt, wie z. B. Anschrift, Beschreibung der örtlichen Gegebenheiten inkl. der von Ihnen hochgeladenen Fotos. Sollten Sie unser Angebot annehmen, verarbeiten wir Ihre Daten aufgrund des bestehenden Vertragsverhältnisses gemäß Art. 6 Abs. 1 lit b DSGVO.',
    targetLanguage:
      'We will ask you for further obligatory details, such as address or description of site conditions, including photos uploaded by you, as part of offer preparation and submission, and purchase arrangement. Should you accept our offer, we will process your data on the basis of the existing contractual relationship in accordance with Article 6(1)(b) of the GDPR.',
  },
  {
    german:
      'Ihre Angaben zu den räumlichen Gegebenheiten verarbeiten wir, um daraus eine zeitliche Aufwandschätzung und die damit zusammenhängende finanzielle Kostenschätzung für unser Angebot vornehmen zu können. Wir verwenden diese als Berechnungsgrundlage für einen marktüblichen Angebotspreis. Die Angaben verarbeiten wir aufgrund unseres berechtigten Interesses nach Art. 6 Abs. 1 lit f DSGVO. Zusätzlich ordnen wir aufgrund unseres berechtigten Interesses der optimalen Gestaltung eines Marktgleichgewichts auch Angebotsanfragen und durchgeführte Installationen räumlich kumuliert einem Bezirk und einem Land zu. Hierdurch wollen wir sicherstellen, dass wir unser Angebot an Kunden und Dienstleistern auch flächendeckend in Deutschland, Österreich und Schweiz bestmöglich anbieten können.',
    targetLanguage:
      'We process details on site conditions in order to be able to estimate the time required and the associated financial costs for our offer. We use this as the basis for calculating a quote that is in line with market conditions. We process the information on the basis of our legitimate interest pursuant to Article 6(1)(f) of the GDPR. In addition, due to our legitimate interest in the optimum structuring of market equilibrium, we also spatially aggregate requests for quotes and installations that have been executed, and assign them to a district and a country. We want to ensure in this way that we can also offer our range of services to customers and service providers throughout Germany, Austria and Switzerland in the best possible way.',
  },
  {
    german:
      'Zum Abschluss der Angebotslegung und späteren Produktinstallation werden Sie gebeten, Ihre freiwillige Be-wertung zu übermitteln, was in Form der Vergabe von Sternen erfolgt. Ihre zusätzliche Bewertung im Freitext-Feld ist freiwillig und hilft uns zur Qualitätssicherung unseres Angebots weiter. Diese Bewertungen sind nicht auf Ihre Person beziehbar.',
    targetLanguage:
      'You will be asked to submit a voluntary star-rating review at the end of the bidding process and ensuing product installation. You may also submit an additional review in the textbox. This is voluntary and helps us to ensure the quality of our service. These evaluations cannot be attributed to you personally.',
  },
  {
    german: 'Weitergabe der Daten',
    targetLanguage: 'Dissemination of data',
    bold: true,
  },
  {
    german:
      'Sofern Sie sich für eine Angebotslegung entscheiden, dass Sie von einem Installateur ein Angebot erhalten möchten, werden wir Ihre Kontaktdaten sowie die oben genannten Angaben zur Beschreibung der örtlichen Gegebenheiten an einen von uns ins Vertrauen gezogenen Installateur weiterleiten, der sich zwecks der weiteren Angebotsausgestaltung und Terminabsprache mit Ihnen in Verbindung setzt.',
    targetLanguage:
      'Should you decide to receive a quote from an installer, we will forward your contact details as well as the above-mentioned information describing site conditions to an installer of our trust, who will contact you to further structure the quote and arrange an appointment.',
  },
  {
    german:
      'Im weiteren Verlauf der Produktinstallation ist es erforderlich, dass in Teilen Ihre Daten im Falle der Beauftragung des Installateurs an den zuständigen Netzbetreiber weiteregegeben werden. Dies erfolgt auf Grund der Meldepflicht aus §19 Niederspannungsanschlussverordnung (NAV).',
    targetLanguage:
      'The further course of product installation requires that some of your data be passed on to the responsible network operator in the event that the installer is commissioned. This is due to the reporting obligation arising from Section 19 of the Low Voltage Connection Ordinance.',
  },
  {
    german:
      'Im Rahmen der Auswertung von Angebotsanfragen nehmen wir Dienstleister in Anspruch, die nach unserer Weisung im Auftrag handeln und die Auswertung vornehmen. Diese Dienstleister, wie im Punkt 3 aufgeführt, haben Ihren Sitz in der EU bzw. dem EWR bzw. verfügen über ein angemessenes Datenschutzniveau (durch den Abschluss von Standarddatenklauseln).',
    targetLanguage:
      'We use service providers who act on our behalf and carry out the evaluation according to our instructions when evaluating requests for quotes. These service providers, as stated in section 3, are based in the EU or the EEA or dispose of an adequate level of data protection (through the conclusion of standard data protection clauses).',
  },
  {
    german: '2. Ihre Registrierung als Installateur',
    targetLanguage: '2. Your registration as an installer',
    subSection: true,
  },
  {
    german: 'Erhebung und Verarbeitung persönlicher Daten',
    targetLanguage: 'Collection and processing of personal data',
    bold: true,
  },
  {
    german: 'Anlage eines Nutzeraccounts',
    targetLanguage: 'Creating a user account',
    bold: true,
  },
  {
    german:
      'Wir verarbeiten im Rahmen Ihrer Anmeldung und unserer Kontaktaufnahme mit Ihnen Ihre E-Mail-Adresse, Ihren Vor- und Zunamen, ggf. auch Ihren Titel, Ihre Mobil-Telefonnummer, Faxnummer, den Namens eines Ansprechpartners, Ihren Firmennamen, die Firmenanschrift sowie Ihre UST-ID. Wir legen für Sie anschließend einen Nutzeraccount als Installateur, im Rahmen eines Vertragsverhältnisses gemäß Art. 6 Abs 1 lit b DSGVO, an.',
    targetLanguage:
      'We will process your email address, your first name and surname, your title if applicable, your mobile telephone number, fax number, the name of a contact person, your company name, the company address and your VAT ID as part of your registration and our contact with you. We will then create a user account for you as an installer within the scope of a contractual relationship in accordance with Article 6(1)(b) of the GDPR.',
  },
  {
    german:
      'Die Kopie Ihres Installateurausweises sowie die Nummer Ihres Ausweises verarbeiten wir gemäß der rechtlichen Verpflichtung Art. 6 Abs 1 lit c DSGVO.',
    targetLanguage:
      'We will process the copy of your installer pass and your ID card number in accordance with the legal obligation under Article 6(1)(c) of the GDPR.',
  },
  {
    german:
      'Über die Platform tendergy haben Sie die Gelegenheit, mit Endkunden Kontakt aufzunehmen zwecks einer Angebotslegung und Terminabsprache sowie der Abwicklung einer Projektinstallation inkl. Der Rechnungslegung an den Kunden.',
    targetLanguage:
      'The tendergy platform allows you to contact end customers to submit a quote and arrange an appointment, as well as process project installations, including invoicing the customer.',
  },
  {
    german: 'Weitergabe der Daten',
    targetLanguage: 'Dissemintation of data',
    bold: true,
  },
  {
    german:
      'Zum Abschluss eines Auftrags hat der Endkunde die Möglichkeit, die vorgenommene Projektinstallation sowie die gesamte Abwicklung zu bewerten, wobei eine Bewertungsskala in Form von Sternen verwendet wird. Da-neben kann der Kunden noch in Freitext-Feldern Bewertungshinweise eingeben. Diese Bewertung können wir, als Schneider Electric, einsehen und verwenden diese zur Qualitätssicherung der, über tendergy, angebotenen Produkte.',
    targetLanguage:
      'The customer will have the opportunity to submit a star-review to rate the project installation as well as the entire execution at the end of an order. The customer will also have the opportunity to add evaluation notes in a textbox. We as Schneider Electric will be able to see this review and will use it for the purpose of quality assurance concerning the products offered on tendergy.',
  },
  {
    german:
      '3. Weitere Angaben zur Webanalyse, Cookies, Protokollierung sowie weiteren Details, den Datenschutz betreffend',
    targetLanguage:
      '3. Further information on web analysis, cookies, logging and other details concerning data protection',
    subSection: true,
  },
  {
    german: 'Weitergabe der Daten für alle Parteien',
    targetLanguage: 'Dissemination of data for all parties',
    bold: true,
  },
  {
    german:
      'Eine Weitergabe an Dritte, zu kommerziellen oder nicht-kommerziellen Zwecken, findet ohne Ihre ausdrückliche Zustimmung grundsätzlich nicht statt. Wir geben Ihre persönlichen Daten nur dann an Dritte weiter, wenn dies gesetzlich zulässig [wie z. B. auf Basis des Artikel 6 DSGVO] und/oder erforderlich ist. Teilweise setzen wir Dienstleister im Rahmen einer Auftragsverarbeitung gemäß Artikel 28 DSGVO ein. Schneider Electric bleibt weiterhin die für die Datenverarbeitung verantwortliche Stelle. Im Folgenden werden die Empfänger im Rahmen der Verarbeitung Ihrer Daten beschrieben.',
    targetLanguage:
      'Disclosure to third parties, for commercial or non-commercial purposes, will in principle not take place without your express consent. We will only disclose your personal data to third parties if this is legally permissible [such as on the basis of Article 6 of the GDPR] and/or necessary. In some cases, we use service providers as part of order processing pursuant to Article 28 of the GDPR. Schneider Electric remains the entity responsible for data processing. The following sections detail the recipients involved in the processing of your data.',
  },
  {
    german: 'Microsoft Cloud Service Azure',
    targetLanguage: 'Microsoft Cloud Service Azure',
    bold: true,
  },
  {
    german:
      'Diese Platform (tendergy) wird auf dem Microsoft Cloud Service Azure betrieben. Ihre Daten werden auf den EU Servern der Microsoft Ireland Operations Ltd, One Microsoft Place, South County Business Park, Leopardstown, Dublin 18, D18 P521, Irland gespeichert. Die Nutzung des Microsoft Cloud Service Azure stellt die fehlerfreie Funktion der Internetplattform tendergy und die Datensicherheit sicher. Ihre Kundendaten werden in den Rechenzentren der Microsoft Cloud Service Azure grundsätzlich durch eine Vielzahl von Technologien und Prozessen geschützt, einschließlich verschiedener Formen der Verschlüsselung. Die von Ihnen zwecks der Bereitstellung der tendergy Platform zur Verfügung gestellten Daten (siehe Erstellung eines Nutzeraccounts) wer-den auf den Servern von Microsoft Cloud Service Azure in der EU unter Einhaltung der DSGVO gespeichert. Die Datenverarbeitung erfolgt auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie können diese Einwilligung jederzeit widerrufen, indem Sie Ihren Nutzeraccount löschen. Die Rechtmäßigkeit der bereits erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt. Die bei uns hinterlegten personenbezogenen Daten werden anschließend automatisch von unseren Servern als auch bei Microsoft Azure nach 90-Tagen Aufbewahrungsfrist gelöscht. Microsoft Cloud Service Azure wird die verarbeiteten Informationen gegebenenfalls an Dritte, z. B. US-Behörden, übertragen, sofern dies gesetzlich vorgeschrieben ist, oder soweit Dritte diese Daten im Auftrag von Microsoft Cloud Service Azure verarbeiten. Weitere Informationen zur Microsoft Azure Cloud finden Sie in der Datenschutzerklärung von Microsoft: <a href="https://www.microsoft.com/de-de/trust-center/privacy">https://www.microsoft.com/de-de/trust-center/privacy</a>',
    targetLanguage:
      'This platform (tendergy) is operated on the Microsoft Azure Cloud Service. Your data is saved on Microsoft Ireland Operations’ EU servers at One Microsoft Place, South County Business Park, Leopardstown, Dublin 18, D18 P521, Ireland. Using Microsoft Azure Cloud Service ensures error-free operation of the tendergy internet platform and data security. Your customer data is principally protected in Microsoft Azure Cloud Service data centres by a variety of technologies and processes, including various forms of encryption. The data provided by you for the deployment the tendergy Platform (see “Creating a user account”) is stored on the servers of Microsoft Azure Cloud Service in the EU in compliance with the GDPR. Data processing is based on your consent (Article 6(1)(a) of the GDPR). You may revoke this consent at any time by deleting your user account. The lawfulness of data processing operations that have already taken place will remain unaffected by such revocation. The personal data that is stored with us will then be automatically deleted from our servers as well as from Microsoft Azure following a 90-day retention period. Microsoft Azure Cloud Service may transfer the processed information to third parties, e.g. US authorities, if required to do so by law or if third parties process this data on behalf of Microsoft Azure Cloud Service. For more information on the Microsoft Azure Cloud, please see Microsoft\'s privacy policy at <a href="https://www.microsoft.com/de-de/trust-center/privacy">https://www.microsoft.com/de-de/trust-center/privacy</a>',
  },
  {
    german: 'Vonage',
    targetLanguage: 'Vonage',
    bold: true,
  },
  {
    german:
      'Diese Platform (tendergy) benutzt Vonage, ein Kommunikationsdiestleister für Videochatfunktionen der Vonage Holdings Corp., 23 Main Street, Holmdel, NJ 07733 USA, sofern Sie hierzu Ihre Einwilligung gemäß Art. 6 Abs. 1 lit. a DSGVO erteilt haben. Die Nutzung des Dienstleisters Vonage ist zum Zweck des Video Chats zur Optimie-rung der Kommunikation zwischen Endnutzer*innen und Installateur*innen von tendergy. Die von Ihnen zwecks der verbesserten Kommunikation angegebenen Daten (Anrede, Name & Email-Adresse) werden auf den Servern von Vonage in der EU, unter Einhaltung der DSGVO gespeichert. Die Datenverarbeitung erfolgt auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie können diese Einwilligung jederzeit widerrufen, indem Sie entweder Ihre Cookie-Einstellung verändern oder Ihren Nutzeraccount löschen. Die Rechtmäßigkeit der bereits erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt. Die von Ihnen zum Zwecke des optimalen Kommunikationsangebotes auf der Platform und beim Platformbetreiber hinterlegten Daten werden von Schneider Electric bis zu Ihren veränderten Cookie-Einstellungen oder bis zur Löschung Ihres Nutzeraccounts automatisch sowohl von den Schneider Electric Servern als auch von den Servern von Vonage gelöscht. Näheres ent-nehmen Sie den Informationen zu “Sicherheit und Datenschutz” von Vonage unter <a href="https://www.vonage.com/resources/articles/gdpr-compliance-our-commitment-to-security/">https://www.vonage.com/resources/articles/gdpr-compliance-our-commitment-to-security/</a> und der privacy Policy <a href="https://www.vonage.com/legal/privacy-policy/?icmp=footer_legalpolicy_privacy">https://www.vonage.com/legal/privacy-policy/?icmp=footer_legalpolicy_privacy</a>',
    targetLanguage:
      'This platform (tendergy) uses Vonage, a communications service provider for videochat capabilities, provided you have consented to this pursuant to Article 6(1)(a) of the GDPR. Vonage is a product of Vonage Holdings Corp., 23 Main Street, Holmdel, NJ 07733 USA. The service provider is used for videochat purposes to optimise communication between tendergy end users and installers. The data you provide to improve communication (title, name & email address) will be stored on Vonage’s EU servers, in compliance with the GDPR. Data processing is based on your consent (Article 6(1)(a) of the GDPR). You may revoke this consent at any time by either changing your cookie settings or deleting your user account. The lawfulness of data processing operations that have already taken place will remain unaffected by such revocation. The data you have provided to the platform and platform operator in order to optimise the platform\'s communication services will be automatically deleted by Schneider Electric from both the Schneider Electric and the Vonage servers until your cookie settings have been changed or until your user account has been deleted. For more information, please refer to the information on Vonage’s “Security and data protection" at <a href="https://www.vonage.com/resources/articles/gdpr-compliance-our-commitment-to-security/">https://www.vonage.com/resources/articles/gdpr-compliance-our-commitment-to-security/</a> und der privacy Policy <a href="https://www.vonage.com/legal/privacy-policy/?icmp=footer_legalpolicy_privacy">https://www.vonage.com/legal/privacy-policy/?icmp=footer_legalpolicy_privacy</a>.',
  },
  {
    german: 'Cookies',
    targetLanguage: 'Cookies',
    bold: true,
  },
  {
    german:
      'Diese Seite verwendet „Cookies“. Cookies sind Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Internetpräsenz ermöglicht und Sie bei Ihrem nächsten Besuch automatisch wiedererkennen. Sie können die Installation der Cookies durch eine entsprechende Einstellung des Cookie Ban-ners oder Ihres Browsers verhindern. Sie haben auch die Möglichkeit, Ihre erteilte Einwilligung jederzeit im Cookie Banner bzw. den Cookie Einstellungen zu widerrufen in dem Sie auf das Cookie-Symbol (Keks-Icon) clicken und die gewünschten Einstellungen anpassen. Dies kann ggf. dazu führen, dass Sie nicht alle Angebote vollumfänglich nutzen können.',
    targetLanguage:
      'This site uses “cookies”. Cookies are text files that are stored on your computer and enable an analysis of the use of the website and automatically recognise you on your next visit. You can prevent the installation of cookies by configuring the cookie banner or your browser accordingly. You may also revoke your consent at any time in the cookie banner or cookie settings by clicking on the cookie symbol (biscuit icon) and adjusting the desired settings. This may result in you not being able to make full use of all services.',
  },
  {
    german:
      'Auf unserer Seite nutzen wir verschiedene Plugins anderer Dienstleister, über die wir Sie nachfolgend informieren möchten.',
    targetLanguage:
      'We use various plugins from other service providers on our site, about which we would like to inform you below.',
  },
  {
    german: 'Protokollierung',
    targetLanguage: 'Logging',
    bold: true,
  },
  {
    german:
      'Bei jedem Zugriff auf die Internetpräsenz werden Protokolle zu statistischen Zwecken erstellt und verarbeitet, wobei der einzelne Benutzer hierbei anonym bleibt:',
    targetLanguage:
      'Logs are created and processed for statistical purposes each time the website is accessed, whereby the individual user remains anonymous:',
  },
  {
    german: '1.	Referrer (Seite, von deren Link Sie zu dieser Internetpräsenz gelangt sind)',
    targetLanguage: '1. Referrer (page link from which you have reached this website)',
  },
  {
    german: '2.	Suchbegriffe (bei Suchmaschinen als Referrer)',
    targetLanguage: '2. Search terms (in cases where search engines are Referrers)',
  },
  {
    german: '3.	IP wird zur Bestimmung des Herkunftslands und des Providers ausgewertet',
    targetLanguage: '3. IP is analysed to determine country of origin and provider.',
  },
  {
    german: '4.	Browser, Betriebssystem, installierte Plug-ins und Bildschirmauflösung',
    targetLanguage: '4. Browser, operating system, installed plug-ins and screen resolution',
  },
  {
    german: '5.	Aufenthaltszeit auf den Seiten',
    targetLanguage: '5. Time spent on pages',
  },
  {
    german: '6.	Art des Endgerätes',
    targetLanguage: '6. Terminal type',
  },
  {
    german:
      'Die genannten Daten werden auf Basis unseres berechtigten Interesses gemäß Art. 6 Abs. 1 lit. f DSGVO durch uns zu folgenden Zwecken verarbeitet:',
    targetLanguage:
      'The aforementioned data is processed by us on the basis of our legitimate interest pursuant to Art. 6 (1) (f) of the GDPR for the following purposes:',
  },
  {
    german: '1.	Gewährleistung eines reibungslosen Verbindungsaufbaus der Internetpräsenz,',
    targetLanguage: '1. Guaranteeing a smooth website connection',
  },
  {
    german: '2.	Gewährleistung einer komfortablen Nutzung unserer Internetpräsenz,',
    targetLanguage: '2. Guaranteeing ease of use of our website',
  },
  {
    german: '3.	Auswertung der Systemsicherheit und -stabilität sowie',
    targetLanguage: '3. Evaluation of system security and stability; and',
  },
  {
    german: '4.	zu weiteren administrativen Zwecken.',
    targetLanguage: '4. Other administrative purposes',
  },
  {
    german:
      'Wir behalten uns vor, diese Daten nachträglich zu prüfen, wenn uns konkrete Anhaltspunkte für eine rechtswidrige Nutzung bekannt werden. Die Daten werden umgehend gelöscht, wenn sie zur Zweckerreichung nicht mehr erforderlich sind, spätestens jedoch nach sechs Monaten.',
    targetLanguage:
      'We reserve the right to review this data retrospectively if we become aware of specific indications of unlawful use. The data will be deleted immediately if it is no longer required to fulfil any purpose, but no later than after six months have lapsed.  ',
  },
  {
    german: 'Aufbewahrung und Löschung der Daten',
    targetLanguage: 'Storage and deletion of data',
    bold: true,
  },
  {
    german:
      'Ihre Daten werden solange aufbewahrt, wie dies für die jeweiligen o. g. Zwecke erforderlich ist. Die Daten werden spätestens nach Beendigung des Vertragsverhältnisses und nach Ablauf der gesetzlichen Aufbewahrungsfristen des Zivil-, Handels- und Steuerrechts gelöscht. In der Regel beträgt die Löschfrist bei Erteilung eines Auftrags und späterer Rechnungsstellung 10 Jahre.',
    targetLanguage:
      'Your data will be kept for as long as necessary to fulfil the purposes mentioned above. The data will be deleted at the latest upon termination of the contractual relationship and upon expiry of the statutory retention periods under civil, commercial and tax law. The deletion period is generally 10 years in cases where an order is placed and subsequently invoiced.',
  },
  {
    german:
      'Sie können Ihren Nutzeraccount jederzeit selbstständig löschen, wobei im Falle eines bereits erteilten Auftrags und späterer Rechnungsstellung die Daten gemäß Art. 18 DSGVO für die Verarbeitung eingeschränkt werden und erst nach Ablauf der gesetzlichen Fristen aus Zivil-, Handels- und Steuerrecht gelöscht werden.',
    targetLanguage:
      'You can delete your user account yourself at any time, although if an order has already been placed and invoices are to be subsequently issued, the data will be restricted for processing in accordance with Art. 18 GDPR and will only be deleted after expiry of the statutory periods under civil, commercial and tax law.',
  },
  {
    german: 'Haftung für eigene Inhalte',
    targetLanguage: 'Liability for own content',
    bold: true,
  },
  {
    german:
      'Die Inhalte dieser Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich.',
    targetLanguage:
      'The content on these pages was created with the utmost diligence. However, we cannot assume any liability for the correctness, completeness and up-to-dateness of the contents. As a service provider, we are responsible for our own content on these pages in accordance with general laws.',
  },
  {
    german: 'Haftung Inhalte fremder Anbieter',
    targetLanguage: 'Liability for the content of third-party providers',
    bold: true,
  },
  {
    german:
      'Von diesen eigenen Inhalten sind Querverweise ("Links") auf die von anderen Anbietern bereitgehaltenen In-halte zu unterscheiden. Auf deren Inhalt haben wir keinen Einfluss; für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich.',
    targetLanguage:
      'This content is to be distinguished from cross-references ("links") to content provided by other providers. We have no influence on their content; the respective provider or operator of the pages is always responsible for the content of the linked pages.',
  },
  {
    german: 'Rechte der Betroffenen',
    targetLanguage: 'Rights of the parties concerned',
    bold: true,
  },
  {
    german:
      'Wir informieren Sie hiermit darüber, dass Sie gemäß Artikel 15 ff. DSGVO uns gegenüber unter den dort defi-nierten Voraussetzungen das Recht auf Auskunft über die betreffenden personenbezogenen Daten sowie auf Berichtigung oder Löschung oder auf Einschränkung der Verarbeitung oder eines Widerspruchsrechts gegen die Verarbeitung sowie des Rechts auf Datenübertragbarkeit haben. Auch haben Sie gemäß Artikel 77 DSGVO das Recht der Beschwerde bei einer Datenschutz-Aufsichtsbehörde, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen diese Verordnung verstößt. Wenn die Verarbeitung auf Artikel 6 Absatz 1 Buchstabe a DSGVO oder Artikel 9 Absatz 2 Buchstabe a DSGVO beruht (Einwilligung), haben Sie ferner das Recht, die Einwilligung jederzeit zu widerrufen, ohne dass die Rechtmäßigkeit der auf-grund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird.',
    targetLanguage:
      'We hereby inform you that, pursuant to Article 15 et seq. of the GDPR, you have the right to information on the personal data concerned, as well as the right to rectification, deletion, or to restriction of processing, or a right to object to processing, as well as the right to data transferability, subject to the conditions defined therein. You also have the right to lodge a complaint with a data protection supervisory authority pursuant to Article 77 of the GDPR if you are of the opinion that the processing of personal data pertaining to you violates this Regulation. If processing is based on Article 6(1)(a) of the GDPR or Article 9(2)(a) of the GDPR (consent), you also have the right to withdraw your consent at any time without affecting the lawfulness of the processing that was undertaken on the basis of your consent up to the time of withdrawal.',
  },
  {
    german: 'Änderung unserer Datenschutzbestimmungen',
    targetLanguage: 'Changes to our data protection policy',
    bold: true,
  },
  {
    german:
      'Wir behalten uns vor, diese Datenschutzerklärung gelegentlich anzupassen, damit sie stets den aktuellen rechtlichen Anforderungen entspricht oder um Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen, z. B. bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt dann die neue Datenschutzerklärung.',
    targetLanguage:
      'We reserve the right to amend this data protection policy from time to time to ensure that it always complies with the current legal requirements or to implement changes to our services in the data protection policy, e.g. when introducing new services. The new data protection policy will then apply to your next visit.',
  },
  {
    german: 'Kontakt zum Datenschutzbeauftragten',
    targetLanguage: 'Contacting the Data Protection Officer',
    bold: true,
  },
  {
    german:
      'Wenn Sie Fragen hinsichtlich der Verarbeitung Ihrer persönlichen Daten haben, können Sie sich direkt an unseren Datenschutzbeauftragten wenden, der mit seinem Team auch im Falle von Auskunftsersuchen, Anträgen oder Beschwerden zur Verfügung steht.',
    targetLanguage:
      'If you have any questions regarding the processing of your personal data, you can contact our Data Protection Officer directly, who, together with his team, is also available for requests for information, applications or complaints.',
  },
  {
    german: 'Der externe Datenschutzbeauftragte ist erreichbar unter:',
    targetLanguage: 'The External Data Protection Officer is available at:',
    bold: true,
  },
  {
    german: 'Dr. Jörn Voßbein, externer Datenschutzbeauftragter,',
    targetLanguage: 'Dr. Jörn Voßbein, External Data Protection Officer,',
  },
  {
    german: 'UIMC Dr. Voßbein GmbH & Co KG',
    targetLanguage: 'UIMC Dr. Voßbein GmbH & Co KG',
  },
  {
    german: 'Otto-Hausmann-Ring 113,',
    targetLanguage: 'Otto-Hausmann-Ring 113,',
  },
  {
    german: '42115 Wuppertal',
    targetLanguage: '42115 Wuppertal',
  },
  {
    german: 'Telefon: +49 202 946 7726 200',
    targetLanguage: 'Telephone: +49 202 946 7726 200',
  },
  {
    german: 'E-Mail: datenschutz.schneider-electric@uimc.de',
    targetLanguage: 'Email: datenschutz.schneider-electric@uimc.de',
  },
];

const DataPrivacyDeEn: React.FC = (props) => {
  return (
    <div className={styles.container}>
      <BiLingualTable columns={columns} dataSource={privacyData} />
    </div>
  );
};

export default DataPrivacyDeEn;
