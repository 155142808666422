import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import BiLingualTable, {
  IBiLingualData,
  rendrerFn,
} from 'components/ui/BiLingualTable/BiLingualTable';
import styles from './DataPrivacy.module.sass';

const columns: ColumnsType<IBiLingualData> = [
  {
    key: 'german',
    title: 'GREENDatenschutzhinweise Tendergy',
    dataIndex: 'german',
    render: rendrerFn,
  },
  {
    key: 'targetLanguage',
    title: 'Informativa sulla privacy dei dati Tendergy',
    dataIndex: 'targetLanguage',
    render: rendrerFn,
  },
];

const privacyData: IBiLingualData[] = [
  {
    german: 'Verantwortlicher:',
    targetLanguage: 'Ente responsabile:',
    bold: true,
  },
  {
    german: 'Schneider Electric GmbH',
    targetLanguage: 'Schneider Electric GmbH',
  },
  {
    german: 'EUREF-Campus 12-13,',
    targetLanguage: 'EUREF-Campus 12-13,',
  },
  {
    german: 'Torgauer Straße 12-15,',
    targetLanguage: 'Torgauer Straße 12-15,',
  },
  {
    german: '10829 Berlin',
    targetLanguage: '10829 Berlin',
  },
  {
    german: 'Deutschland',
    targetLanguage: 'Germania',
  },
  {
    german: 'Telefon: +49 30 23 88 84 - 240',
    targetLanguage: 'Telefono: +49 30 23 88 84 – 240',
  },
  {
    german: 'E-Mail: info@inno2fleet.com',
    targetLanguage: 'E-mail: info@inno2fleet.com',
  },
  {
    german:
      'Wir nehmen den Schutz Ihrer persönlichen Daten sehr ernst. So behandeln wir Ihre personenbezogenen Daten selbstverständlich stets entsprechend der gesetzlichen Datenschutzvorschriften. Wir haben einen fachkundigen und zuverlässigen externen Datenschutzbeauftragten bestellt.',
    targetLanguage:
      'Prendiamo molto seriamente la protezione dei tuoi dati personali. Ciò significa che tratteremo sempre i tuoi dati personali in conformità con le normative legali sulla protezione dei dati. Abbiamo nominato un responsabile esterno della protezione dei dati specializzato e affidabile.',
  },
  {
    german:
      'Die externe Datenschutzbeauftragung wird von der UIMC Dr. Voßbein GmbH & Co KG wahrgenommen (<a href="https://www.uimc.de/datenschutz">https://www.uimc.de/datenschutz</a>), mit Dr. Jörn Voßbein als extern bestelltem Datenschutzbeauftragten.',
    targetLanguage:
      'La supervisione esterna della protezione dei dati è effettuata da UIMC Dr. Voßbein GmbH & Co KG (<a href="https://www.uimc.de/datenschutz">https://www.uimc.de/datenschutz</a>), con il dott. Jörn Voßbein in qualità di responsabile esterno della protezione dei dati.',
  },
  {
    german:
      'Nachfolgend möchten wir Sie über die Verarbeitung von personenbezogenen Daten informieren.',
    targetLanguage:
      'Di seguito desideriamo informarvi sulle modalità di trattamento dei dati personali.',
  },
  {
    german: '1. Ihre Registrierung als Kunde/Endverbraucher',
    targetLanguage: '1. La tua registrazione come cliente/utente finale',
    subSection: true,
  },
  {
    german: 'Erhebung und Verarbeitung persönlicher Daten',
    targetLanguage: 'Raccolta ed elaborazione dei dati personali',
  },
  {
    german: 'Anlage eines Nutzeraccounts',
    targetLanguage: 'Creazione di un account utente',
    bold: true,
  },
  {
    german:
      'Wir verarbeiten im Rahmen Ihrer ersten Anmeldung und unserer Kontaktaufnahme mit Ihnen Ihre E-Mail-Adresse, Ihren Vor- und Zunamen, ggf. auch Ihren Titel sowie Ihre Telefonnummer. Wir legen für Sie danach einen Nutzeraccount im Rahmen eines Vertragsverhältnisses gemäß Art. 6 Abs 1 lit b DSGVO an.',
    targetLanguage:
      "Come parte della tua registrazione iniziale e del nostro contatto con te, elaboriamo il tuo indirizzo e-mail, il tuo nome e cognome, il tuo titolo, se applicabile, e il tuo numero di telefono. Creeremo quindi un account utente per te come parte di un rapporto contrattuale in conformità con l'articolo 6(1)(b) del GDPR.",
  },
  {
    german: 'Angebotsanfrage/-einholung',
    targetLanguage: 'Richiesta di preventivo/Approvvigionamento',
    bold: true,
  },
  {
    german:
      'Im Rahmen der Angebotsvorbereitung, Angebotslegung und Vermittlung des Produktkaufs bzw. der Produktinstallation werden außerdem noch erforderliche Angaben erfragt, wie z. B. Anschrift, Beschreibung der örtlichen Gegebenheiten inkl. der von Ihnen hochgeladenen Fotos. Sollten Sie unser Angebot annehmen, verarbeiten wir Ihre Daten aufgrund des bestehenden Vertragsverhältnisses gemäß Art. 6 Abs. 1 lit b DSGVO.',
    targetLanguage:
      "Ti chiederemo ulteriori dettagli obbligatori, come l'indirizzo o la descrizione delle condizioni del sito, comprese le foto da te caricate, come parte della preparazione e dell'invio dell'offerta e dell'accordo di acquisto. Se accetti la nostra offerta, elaboreremo i tuoi dati sulla base del rapporto contrattuale esistente in conformità con l'articolo 6(1)(b) del GDPR.",
  },
  {
    german:
      'Ihre Angaben zu den räumlichen Gegebenheiten verarbeiten wir, um daraus eine zeitliche Aufwandschätzung und die damit zusammenhängende finanzielle Kostenschätzung für unser Angebot vornehmen zu können. Wir verwenden diese als Berechnungsgrundlage für einen marktüblichen Angebotspreis. Die Angaben verarbeiten wir aufgrund unseres berechtigten Interesses nach Art. 6 Abs. 1 lit f DSGVO. Zusätzlich ordnen wir aufgrund unseres berechtigten Interesses der optimalen Gestaltung eines Marktgleichgewichts auch Angebotsanfragen und durchgeführte Installationen räumlich kumuliert einem Bezirk und einem Land zu. Hierdurch wollen wir sicherstellen, dass wir unser Angebot an Kunden und Dienstleistern auch flächendeckend in Deutschland, Österreich und Schweiz bestmöglich anbieten können.',
    targetLanguage:
      "Elaboriamo i dettagli sulle condizioni del sito per poter stimare il tempo necessario e i costi finanziari associati per la nostra offerta. Utilizziamo questo come base per calcolare un preventivo in linea con le condizioni di mercato. Elaboriamo le informazioni sulla base del nostro legittimo interesse ai sensi dell'articolo 6(1)(f) del GDPR. Inoltre, a causa del nostro legittimo interesse nella strutturazione ottimale dell'equilibrio di mercato, aggreghiamo anche spazialmente le richieste di preventivo e le installazioni eseguite e le assegniamo a un distretto e a un paese. Vogliamo garantire in questo modo di poter offrire la nostra gamma di servizi anche a clienti e fornitori di servizi in tutta Germania, Austria e Svizzera nel miglior modo possibile.",
  },
  {
    german:
      'Zum Abschluss der Angebotslegung und späteren Produktinstallation werden Sie gebeten, Ihre freiwillige Be-wertung zu übermitteln, was in Form der Vergabe von Sternen erfolgt. Ihre zusätzliche Bewertung im Freitext-Feld ist freiwillig und hilft uns zur Qualitätssicherung unseres Angebots weiter. Diese Bewertungen sind nicht auf Ihre Person beziehbar.',
    targetLanguage:
      "Ti verrà chiesto di inviare una recensione volontaria con valutazione a stelle alla fine del processo di offerta e della successiva installazione del prodotto. Puoi anche inviare un'ulteriore recensione nella casella di testo. Questa è volontaria e ci aiuta a garantire la qualità del nostro servizio. Queste valutazioni non possono essere attribuite a te personalmente.",
  },
  {
    german: 'Weitergabe der Daten',
    targetLanguage: 'Diffusione dei dati',
    bold: true,
  },
  {
    german:
      'Sofern Sie sich für eine Angebotslegung entscheiden, dass Sie von einem Installateur ein Angebot erhalten möchten, werden wir Ihre Kontaktdaten sowie die oben genannten Angaben zur Beschreibung der örtlichen Gegebenheiten an einen von uns ins Vertrauen gezogenen Installateur weiterleiten, der sich zwecks der weiteren Angebotsausgestaltung und Terminabsprache mit Ihnen in Verbindung setzt.',
    targetLanguage:
      'Se decidete di ricevere un preventivo da un installatore, inoltreremo i vostri dati di contatto e le informazioni sopra menzionate che descrivono le condizioni del sito a un installatore di nostra fiducia, che vi contatterà per strutturare ulteriormente il preventivo e fissare un appuntamento.',
  },
  {
    german:
      'Im weiteren Verlauf der Produktinstallation ist es erforderlich, dass in Teilen Ihre Daten im Falle der Beauftragung des Installateurs an den zuständigen Netzbetreiber weiteregegeben werden. Dies erfolgt auf Grund der Meldepflicht aus §19 Niederspannungsanschlussverordnung (NAV).',
    targetLanguage:
      "L'ulteriore corso dell'installazione del prodotto richiede che alcuni dei tuoi dati vengano trasmessi al gestore di rete responsabile nel caso in cui l'installatore venga incaricato. Ciò è dovuto all'obbligo di segnalazione derivante dalla Sezione 19 dell'Ordinanza sulla connessione a bassa tensione.",
  },
  {
    german:
      'Im Rahmen der Auswertung von Angebotsanfragen nehmen wir Dienstleister in Anspruch, die nach unserer Weisung im Auftrag handeln und die Auswertung vornehmen. Diese Dienstleister, wie im Punkt 3 aufgeführt, haben Ihren Sitz in der EU bzw. dem EWR bzw. verfügen über ein angemessenes Datenschutzniveau (durch den Abschluss von Standarddatenklauseln).',
    targetLanguage:
      "Utilizziamo fornitori di servizi che agiscono per nostro conto ed eseguono la valutazione secondo le nostre istruzioni quando valutiamo le richieste di preventivo. Questi fornitori di servizi, come indicato nella sezione 3, hanno sede nell'UE o nello SEE o dispongono di un livello adeguato di protezione dei dati (attraverso la conclusione di clausole standard di protezione dei dati).",
  },
  {
    german: '2. Ihre Registrierung als Installateur',
    targetLanguage: '2. La tua registrazione come installatore',
    subSection: true,
  },
  {
    german: 'Erhebung und Verarbeitung persönlicher Daten',
    targetLanguage: 'Raccolta ed elaborazione dei dati personali',
    bold: true,
  },
  {
    german: 'Anlage eines Nutzeraccounts',
    targetLanguage: 'Creazione di un account utente',
    bold: true,
  },
  {
    german:
      'Wir verarbeiten im Rahmen Ihrer Anmeldung und unserer Kontaktaufnahme mit Ihnen Ihre E-Mail-Adresse, Ihren Vor- und Zunamen, ggf. auch Ihren Titel, Ihre Mobil-Telefonnummer, Faxnummer, den Namens eines Ansprechpartners, Ihren Firmennamen, die Firmenanschrift sowie Ihre UST-ID. Wir legen für Sie anschließend einen Nutzeraccount als Installateur, im Rahmen eines Vertragsverhältnisses gemäß Art. 6 Abs 1 lit b DSGVO, an.',
    targetLanguage:
      "Elaboreremo il tuo indirizzo e-mail, il tuo nome e cognome, il tuo titolo, se applicabile, il tuo numero di cellulare, il numero di fax, il nome di una persona di contatto, il nome della tua azienda, l'indirizzo dell'azienda e il tuo numero di partita IVA come parte della tua registrazione e del nostro contatto con te. Creeremo quindi un account utente per te come installatore nell'ambito di un rapporto contrattuale in conformità con l'articolo 6(1)(b) del GDPR.",
  },
  {
    german:
      'Die Kopie Ihres Installateurausweises sowie die Nummer Ihres Ausweises verarbeiten wir gemäß der rechtlichen Verpflichtung Art. 6 Abs 1 lit c DSGVO.',
    targetLanguage:
      "Elaboreremo la copia del tuo pass installatore e il numero della tua carta d'identità in conformità con l'obbligo legale ai sensi dell'articolo 6(1)(c) del GDPR.",
  },
  {
    german:
      'Über die Platform tendergy haben Sie die Gelegenheit, mit Endkunden Kontakt aufzunehmen zwecks einer Angebotslegung und Terminabsprache sowie der Abwicklung einer Projektinstallation inkl. Der Rechnungslegung an den Kunden.',
    targetLanguage:
      'La piattaforma tendergy consente di contattare i clienti finali per inviare un preventivo e fissare un appuntamento, nonché di elaborare le installazioni dei progetti, inclusa la fatturazione al cliente.',
  },
  {
    german: 'Weitergabe der Daten',
    targetLanguage: 'Diffusione dei dati',
    bold: true,
  },
  {
    german:
      'Zum Abschluss eines Auftrags hat der Endkunde die Möglichkeit, die vorgenommene Projektinstallation sowie die gesamte Abwicklung zu bewerten, wobei eine Bewertungsskala in Form von Sternen verwendet wird. Da-neben kann der Kunden noch in Freitext-Feldern Bewertungshinweise eingeben. Diese Bewertung können wir, als Schneider Electric, einsehen und verwenden diese zur Qualitätssicherung der, über tendergy, angebotenen Produkte.',
    targetLanguage:
      "Il cliente avrà l'opportunità di inviare una recensione a stelle per valutare l'installazione del progetto e l'intera esecuzione alla fine di un ordine. Il cliente avrà anche l'opportunità di aggiungere note di valutazione in una casella di testo. Noi come Schneider Electric saremo in grado di vedere questa recensione e la utilizzeremo allo scopo di garantire la qualità dei prodotti offerti su tendergy.",
  },
  {
    german:
      '3. Weitere Angaben zur Webanalyse, Cookies, Protokollierung sowie weiteren Details, den Datenschutz betreffend',
    targetLanguage:
      "3. Ulteriori informazioni sull'analisi web, sui cookie, sulla registrazione e altri dettagli riguardanti la protezione dei dati",
    subSection: true,
  },
  {
    german: 'Weitergabe der Daten für alle Parteien',
    targetLanguage: 'Diffusione dei dati a tutte le parti',
    bold: true,
  },
  {
    german:
      'Eine Weitergabe an Dritte, zu kommerziellen oder nicht-kommerziellen Zwecken, findet ohne Ihre ausdrückliche Zustimmung grundsätzlich nicht statt. Wir geben Ihre persönlichen Daten nur dann an Dritte weiter, wenn dies gesetzlich zulässig [wie z. B. auf Basis des Artikel 6 DSGVO] und/oder erforderlich ist. Teilweise setzen wir Dienstleister im Rahmen einer Auftragsverarbeitung gemäß Artikel 28 DSGVO ein. Schneider Electric bleibt weiterhin die für die Datenverarbeitung verantwortliche Stelle. Im Folgenden werden die Empfänger im Rahmen der Verarbeitung Ihrer Daten beschrieben.',
    targetLanguage:
      "La divulgazione a terzi, per scopi commerciali o non commerciali, in linea di principio non avverrà senza il tuo esplicito consenso. Divulgheremo i tuoi dati personali a terzi solo se ciò è legalmente consentito [ad esempio sulla base dell'articolo 6 del GDPR] e/o necessario. In alcuni casi, utilizziamo fornitori di servizi come parte dell'elaborazione degli ordini ai sensi dell'articolo 28 del GDPR. Schneider Electric rimane l'entità responsabile dell'elaborazione dei dati. Le sezioni seguenti descrivono in dettaglio i destinatari coinvolti nell'elaborazione dei tuoi dati.",
  },
  {
    german: 'Microsoft Cloud Service Azure',
    targetLanguage: 'Microsoft Cloud Service Azure',
    bold: true,
  },
  {
    german:
      'Diese Platform (tendergy) wird auf dem Microsoft Cloud Service Azure betrieben. Ihre Daten werden auf den EU Servern der Microsoft Ireland Operations Ltd, One Microsoft Place, South County Business Park, Leopardstown, Dublin 18, D18 P521, Irland gespeichert. Die Nutzung des Microsoft Cloud Service Azure stellt die fehlerfreie Funktion der Internetplattform tendergy und die Datensicherheit sicher. Ihre Kundendaten werden in den Rechenzentren der Microsoft Cloud Service Azure grundsätzlich durch eine Vielzahl von Technologien und Prozessen geschützt, einschließlich verschiedener Formen der Verschlüsselung. Die von Ihnen zwecks der Bereitstellung der tendergy Platform zur Verfügung gestellten Daten (siehe Erstellung eines Nutzeraccounts) wer-den auf den Servern von Microsoft Cloud Service Azure in der EU unter Einhaltung der DSGVO gespeichert. Die Datenverarbeitung erfolgt auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie können diese Einwilligung jederzeit widerrufen, indem Sie Ihren Nutzeraccount löschen. Die Rechtmäßigkeit der bereits erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt. Die bei uns hinterlegten personenbezogenen Daten werden anschließend automatisch von unseren Servern als auch bei Microsoft Azure nach 90-Tagen Aufbewahrungsfrist gelöscht. Microsoft Cloud Service Azure wird die verarbeiteten Informationen gegebenenfalls an Dritte, z. B. US-Behörden, übertragen, sofern dies gesetzlich vorgeschrieben ist, oder soweit Dritte diese Daten im Auftrag von Microsoft Cloud Service Azure verarbeiten. Weitere Informationen zur Microsoft Azure Cloud finden Sie in der Datenschutzerklärung von Microsoft: <a href="https://www.microsoft.com/de-de/trust-center/privacy">https://www.microsoft.com/de-de/trust-center/privacy</a>',
    targetLanguage:
      "Questa piattaforma (tendergy) è gestita sul servizio cloud Microsoft Azure. I tuoi dati vengono salvati sui server UE di Microsoft Ireland Operations presso One Microsoft Place, South County Business Park, Leopardstown, Dublino 18, D18 P521, Irlanda. L'utilizzo del servizio cloud Microsoft Azure garantisce un funzionamento senza errori della piattaforma Internet tendergy e la sicurezza dei dati. I tuoi dati cliente sono protetti principalmente nei data center del servizio cloud Microsoft Azure da una varietà di tecnologie e processi, tra cui varie forme di crittografia. I dati da te forniti per l'implementazione della piattaforma tendergy (vedi \"Creazione di un account utente\") vengono archiviati sui server del servizio cloud Microsoft Azure nell'UE in conformità con il GDPR. Il trattamento dei dati si basa sul tuo consenso (articolo 6(1)(a) del GDPR). Puoi revocare questo consenso in qualsiasi momento eliminando il tuo account utente. La liceità delle operazioni di trattamento dei dati che hanno già avuto luogo rimarrà inalterata da tale revoca. I dati personali archiviati presso di noi verranno quindi automaticamente eliminati dai nostri server e da Microsoft Azure dopo un periodo di conservazione di 90 giorni. Microsoft Azure Cloud Service può trasferire le informazioni elaborate a terze parti, ad esempio alle autorità statunitensi, se richiesto dalla legge o se terze parti elaborano questi dati per conto di Microsoft Azure Cloud Service. Per ulteriori informazioni su Microsoft Azure Cloud, consultare l'informativa sulla privacy di Microsoft all'indirizzo <a href=\"https://www.microsoft.com/de-de/trust-center/privacy\">https://www.microsoft.com/de-de/trust-center/privacy</a>",
  },
  {
    german: 'Vonage',
    targetLanguage: 'Vonage',
    bold: true,
  },
  {
    german:
      'Diese Platform (tendergy) benutzt Vonage, ein Kommunikationsdiestleister für Videochatfunktionen der Vonage Holdings Corp., 23 Main Street, Holmdel, NJ 07733 USA, sofern Sie hierzu Ihre Einwilligung gemäß Art. 6 Abs. 1 lit. a DSGVO erteilt haben. Die Nutzung des Dienstleisters Vonage ist zum Zweck des Video Chats zur Optimie-rung der Kommunikation zwischen Endnutzer*innen und Installateur*innen von tendergy. Die von Ihnen zwecks der verbesserten Kommunikation angegebenen Daten (Anrede, Name & Email-Adresse) werden auf den Servern von Vonage in der EU, unter Einhaltung der DSGVO gespeichert. Die Datenverarbeitung erfolgt auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie können diese Einwilligung jederzeit widerrufen, indem Sie entweder Ihre Cookie-Einstellung verändern oder Ihren Nutzeraccount löschen. Die Rechtmäßigkeit der bereits erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt. Die von Ihnen zum Zwecke des optimalen Kommunikationsangebotes auf der Platform und beim Platformbetreiber hinterlegten Daten werden von Schneider Electric bis zu Ihren veränderten Cookie-Einstellungen oder bis zur Löschung Ihres Nutzeraccounts automatisch sowohl von den Schneider Electric Servern als auch von den Servern von Vonage gelöscht. Näheres ent-nehmen Sie den Informationen zu “Sicherheit und Datenschutz” von Vonage unter <a href="https://www.vonage.com/resources/articles/gdpr-compliance-our-commitment-to-security/">https://www.vonage.com/resources/articles/gdpr-compliance-our-commitment-to-security/</a> und der privacy Policy <a href="https://www.vonage.com/legal/privacy-policy/?icmp=footer_legalpolicy_privacy">https://www.vonage.com/legal/privacy-policy/?icmp=footer_legalpolicy_privacy</a>',
    targetLanguage:
      'Questa piattaforma (tendergy) utilizza Vonage, un fornitore di servizi di comunicazione per funzionalità di videochat, a condizione che tu abbia acconsentito a ciò ai sensi dell\'articolo 6(1)(a) del GDPR. Vonage è un prodotto di Vonage Holdings Corp., 23 Main Street, Holmdel, NJ 07733 USA. Il fornitore di servizi viene utilizzato per scopi di videochat per ottimizzare la comunicazione tra gli utenti finali di tendergy e gli installatori. I dati forniti per migliorare la comunicazione (titolo, nome e indirizzo e-mail) verranno archiviati sui server UE di Vonage, in conformità con il GDPR. Il trattamento dei dati si basa sul tuo consenso (articolo 6(1)(a) del GDPR). Puoi revocare questo consenso in qualsiasi momento modificando le impostazioni dei cookie o eliminando il tuo account utente. La liceità delle operazioni di trattamento dei dati già avvenute non verrà influenzata da tale revoca. I dati che hai fornito alla piattaforma e all\'operatore della piattaforma per ottimizzare i servizi di comunicazione della piattaforma verranno automaticamente eliminati da Schneider Electric sia dai server Schneider Electric che da quelli Vonage finché non saranno state modificate le tue impostazioni sui cookie o finché non sarà stato eliminato il tuo account utente. Per maggiori informazioni, consulta le informazioni su "Sicurezza e protezione dei dati" di Vonage all\'indirizzo <a href="https://www.vonage.com/resources/articles/gdpr-compliance-our-commitment-to-security/">https://www.vonage.com/resources/articles/gdpr-compliance-our-commitment-to-security/</a>e l\'Informativa sulla privacy <a href="https://www.vonage.com/legal/privacy-policy/?icmp=footer_legalpolicy_privacy">https://www.vonage.com/legal/privacy-policy/?icmp=footer_legalpolicy_privacy</a>.',
  },
  {
    german: 'Cookies',
    targetLanguage: 'Cookies',
    bold: true,
  },
  {
    german:
      'Diese Seite verwendet „Cookies“. Cookies sind Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Internetpräsenz ermöglicht und Sie bei Ihrem nächsten Besuch automatisch wiedererkennen. Sie können die Installation der Cookies durch eine entsprechende Einstellung des Cookie Ban-ners oder Ihres Browsers verhindern. Sie haben auch die Möglichkeit, Ihre erteilte Einwilligung jederzeit im Cookie Banner bzw. den Cookie Einstellungen zu widerrufen in dem Sie auf das Cookie-Symbol (Keks-Icon) clicken und die gewünschten Einstellungen anpassen. Dies kann ggf. dazu führen, dass Sie nicht alle Angebote vollumfänglich nutzen können.',
    targetLanguage:
      "Questo sito utilizza i \"cookie\". I cookie sono file di testo che vengono salvati sul tuo computer e consentono un'analisi dell'utilizzo del sito Web e ti riconoscono automaticamente alla tua prossima visita. Puoi impedire l'installazione dei cookie configurando di conseguenza il banner dei cookie o il tuo browser. Puoi anche revocare il tuo consenso in qualsiasi momento nel banner dei cookie o nelle impostazioni dei cookie cliccando sul simbolo del cookie (icona del biscotto) e regolando le impostazioni desiderate. Ciò potrebbe comportare l'impossibilità di utilizzare appieno tutti i servizi.",
  },
  {
    german:
      'Auf unserer Seite nutzen wir verschiedene Plugins anderer Dienstleister, über die wir Sie nachfolgend informieren möchten.',
    targetLanguage:
      'Sul nostro sito utilizziamo diversi plugin di altri fornitori di servizi, sui quali vorremmo informarvi di seguito.',
  },
  {
    german: 'Protokollierung',
    targetLanguage: 'Registrazione',
    bold: true,
  },
  {
    german:
      'Bei jedem Zugriff auf die Internetpräsenz werden Protokolle zu statistischen Zwecken erstellt und verarbeitet, wobei der einzelne Benutzer hierbei anonym bleibt:',
    targetLanguage:
      "Ogni volta che si accede al sito web vengono creati ed elaborati dei log a fini statistici, mantenendo l'anonimato del singolo utente:",
  },
  {
    german: '1.	Referrer (Seite, von deren Link Sie zu dieser Internetpräsenz gelangt sind)',
    targetLanguage: '1. Referrer (link alla pagina da cui hai raggiunto questo sito web)',
  },
  {
    german: '2.	Suchbegriffe (bei Suchmaschinen als Referrer)',
    targetLanguage: '2. Termini di ricerca (nei casi in cui i motori di ricerca sono Referrer)',
  },
  {
    german: '3.	IP wird zur Bestimmung des Herkunftslands und des Providers ausgewertet',
    targetLanguage: "3. L'IP viene analizzato per determinare il paese di origine e il fornitore.",
  },
  {
    german: '4.	Browser, Betriebssystem, installierte Plug-ins und Bildschirmauflösung',
    targetLanguage: '4. Browser, sistema operativo, plug-in installati e risoluzione dello schermo',
  },
  {
    german: '5.	Aufenthaltszeit auf den Seiten',
    targetLanguage: '5. Tempo trascorso sulle pagine',
  },
  {
    german: '6.	Art des Endgerätes',
    targetLanguage: '6. Tipo di terminale',
  },
  {
    german:
      'Die genannten Daten werden auf Basis unseres berechtigten Interesses gemäß Art. 6 Abs. 1 lit. f DSGVO durch uns zu folgenden Zwecken verarbeitet:',
    targetLanguage:
      "I dati sopra menzionati vengono da noi trattati sulla base del nostro legittimo interesse ai sensi dell'art. 6 (1) (f) del GDPR per le seguenti finalità:",
  },
  {
    german: '1.	Gewährleistung eines reibungslosen Verbindungsaufbaus der Internetpräsenz,',
    targetLanguage: '1. Garantire una connessione fluida al sito web',
  },
  {
    german: '2.	Gewährleistung einer komfortablen Nutzung unserer Internetpräsenz,',
    targetLanguage: "2. Garantire la facilità d'uso del nostro sito web",
  },
  {
    german: '3.	Auswertung der Systemsicherheit und -stabilität sowie',
    targetLanguage: '3. Valutazione della sicurezza e della stabilità del sistema; e',
  },
  {
    german: '4.	zu weiteren administrativen Zwecken.',
    targetLanguage: '4. Altri scopi amministrativi',
  },
  {
    german:
      'Wir behalten uns vor, diese Daten nachträglich zu prüfen, wenn uns konkrete Anhaltspunkte für eine rechtswidrige Nutzung bekannt werden. Die Daten werden umgehend gelöscht, wenn sie zur Zweckerreichung nicht mehr erforderlich sind, spätestens jedoch nach sechs Monaten.',
    targetLanguage:
      'Ci riserviamo il diritto di esaminare retrospettivamente questi dati se veniamo a conoscenza di indicazioni specifiche di utilizzo illecito. I dati verranno cancellati immediatamente se non sono più necessari per soddisfare alcuno scopo, ma non oltre sei mesi.',
  },
  {
    german: 'Aufbewahrung und Löschung der Daten',
    targetLanguage: 'Conservazione e cancellazione dei dati',
    bold: true,
  },
  {
    german:
      'Ihre Daten werden solange aufbewahrt, wie dies für die jeweiligen o. g. Zwecke erforderlich ist. Die Daten werden spätestens nach Beendigung des Vertragsverhältnisses und nach Ablauf der gesetzlichen Aufbewahrungsfristen des Zivil-, Handels- und Steuerrechts gelöscht. In der Regel beträgt die Löschfrist bei Erteilung eines Auftrags und späterer Rechnungsstellung 10 Jahre.',
    targetLanguage:
      'I tuoi dati saranno conservati per tutto il tempo necessario al raggiungimento delle finalità sopra menzionate. I dati saranno cancellati al più tardi alla cessazione del rapporto contrattuale e alla scadenza dei periodi di conservazione previsti dalla legge in materia civile, commerciale e fiscale. Il periodo di cancellazione è generalmente di 10 anni nei casi in cui un ordine viene effettuato e successivamente fatturato.',
  },
  {
    german:
      'Sie können Ihren Nutzeraccount jederzeit selbstständig löschen, wobei im Falle eines bereits erteilten Auftrags und späterer Rechnungsstellung die Daten gemäß Art. 18 DSGVO für die Verarbeitung eingeschränkt werden und erst nach Ablauf der gesetzlichen Fristen aus Zivil-, Handels- und Steuerrecht gelöscht werden.',
    targetLanguage:
      "Puoi cancellare il tuo account utente in qualsiasi momento, ma se è già stato effettuato un ordine e successivamente devono essere emesse fatture, il trattamento dei dati sarà limitato ai sensi dell'art. 18 GDPR e verrà cancellato solo dopo la scadenza dei termini di legge previsti dal diritto civile, commerciale e fiscale.",
  },
  {
    german: 'Haftung für eigene Inhalte',
    targetLanguage: 'Responsabilità per i propri contenuti',
    bold: true,
  },
  {
    german:
      'Die Inhalte dieser Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich.',
    targetLanguage:
      "Il contenuto di queste pagine è stato creato con la massima diligenza. Tuttavia, non possiamo assumerci alcuna responsabilità per la correttezza, la completezza e l'attualità dei contenuti. Come fornitore di servizi, siamo responsabili per i nostri contenuti su queste pagine in conformità con le leggi generali.",
  },
  {
    german: 'Haftung Inhalte fremder Anbieter',
    targetLanguage: 'Responsabilità per i contenuti di fornitori terzi',
    bold: true,
  },
  {
    german:
      'Von diesen eigenen Inhalten sind Querverweise ("Links") auf die von anderen Anbietern bereitgehaltenen In-halte zu unterscheiden. Auf deren Inhalt haben wir keinen Einfluss; für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich.',
    targetLanguage:
      'Questo contenuto deve essere distinto dai riferimenti incrociati ("link") a contenuti forniti da altri provider. Non abbiamo alcuna influenza sul loro contenuto; il rispettivo provider o gestore delle pagine è sempre responsabile del contenuto delle pagine collegate.',
  },
  {
    german: 'Rechte der Betroffenen',
    targetLanguage: 'Diritti degli interessati',
    bold: true,
  },
  {
    german:
      'Wir informieren Sie hiermit darüber, dass Sie gemäß Artikel 15 ff. DSGVO uns gegenüber unter den dort defi-nierten Voraussetzungen das Recht auf Auskunft über die betreffenden personenbezogenen Daten sowie auf Berichtigung oder Löschung oder auf Einschränkung der Verarbeitung oder eines Widerspruchsrechts gegen die Verarbeitung sowie des Rechts auf Datenübertragbarkeit haben. Auch haben Sie gemäß Artikel 77 DSGVO das Recht der Beschwerde bei einer Datenschutz-Aufsichtsbehörde, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen diese Verordnung verstößt. Wenn die Verarbeitung auf Artikel 6 Absatz 1 Buchstabe a DSGVO oder Artikel 9 Absatz 2 Buchstabe a DSGVO beruht (Einwilligung), haben Sie ferner das Recht, die Einwilligung jederzeit zu widerrufen, ohne dass die Rechtmäßigkeit der auf-grund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird.',
    targetLanguage:
      "Con la presente La informiamo che, ai sensi dell'articolo 15 e seguenti del GDPR, Lei ha il diritto di informazione sui dati personali interessati, nonché il diritto di rettifica, cancellazione o limitazione del trattamento, o il diritto di opposizione al trattamento, nonché il diritto alla portabilità dei dati, fatte salve le condizioni ivi definite. Lei ha inoltre il diritto di proporre reclamo a un'autorità di controllo per la protezione dei dati ai sensi dell'articolo 77 del GDPR se ritiene che il trattamento dei dati personali che La riguardano violi il presente regolamento. Se il trattamento è basato sull'articolo 6(1)(a) del GDPR o sull'articolo 9(2)(a) del GDPR (consenso), Lei ha inoltre il diritto di revocare il Suo consenso in qualsiasi momento senza pregiudicare la liceità del trattamento effettuato sulla base del Suo consenso fino al momento della revoca.",
  },
  {
    german: 'Änderung unserer Datenschutzbestimmungen',
    targetLanguage: 'Modifiche alla nostra politica sulla protezione dei dati',
    bold: true,
  },
  {
    german:
      'Wir behalten uns vor, diese Datenschutzerklärung gelegentlich anzupassen, damit sie stets den aktuellen rechtlichen Anforderungen entspricht oder um Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen, z. B. bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt dann die neue Datenschutzerklärung.',
    targetLanguage:
      'Ci riserviamo il diritto di modificare questa politica sulla protezione dei dati di volta in volta per garantire che sia sempre conforme ai requisiti legali correnti o per implementare modifiche ai nostri servizi nella politica sulla protezione dei dati, ad esempio quando si introducono nuovi servizi. La nuova politica sulla protezione dei dati si applicherà quindi alla tua prossima visita.',
  },
  {
    german: 'Kontakt zum Datenschutzbeauftragten',
    targetLanguage: 'Contattare il Responsabile della Protezione dei Dati',
    bold: true,
  },
  {
    german:
      'Wenn Sie Fragen hinsichtlich der Verarbeitung Ihrer persönlichen Daten haben, können Sie sich direkt an unseren Datenschutzbeauftragten wenden, der mit seinem Team auch im Falle von Auskunftsersuchen, Anträgen oder Beschwerden zur Verfügung steht.',
    targetLanguage:
      'In caso di domande relative al trattamento dei tuoi dati personali, puoi contattare direttamente il nostro Responsabile della protezione dei dati, che, insieme al suo team, è a disposizione anche per richieste di informazioni, candidature o reclami.',
  },
  {
    german: 'Der externe Datenschutzbeauftragte ist erreichbar unter:',
    targetLanguage:
      'Il Responsabile esterno della protezione dei dati è disponibile al seguente indirizzo:',
    bold: true,
  },
  {
    german: 'Dr. Jörn Voßbein, externer Datenschutzbeauftragter,',
    targetLanguage: 'Dott. Jörn Voßbein, Responsabile esterno della protezione dei dati,',
  },
  {
    german: 'UIMC Dr. Voßbein GmbH & Co KG',
    targetLanguage: 'UIMC Dr. Voßbein GmbH & Co KG',
  },
  {
    german: 'Otto-Hausmann-Ring 113,',
    targetLanguage: 'Otto-Hausmann-Ring 113,',
  },
  {
    german: '42115 Wuppertal',
    targetLanguage: '42115 Wuppertal',
  },
  {
    german: 'Telefon: +49 202 946 7726 200',
    targetLanguage: 'Telefono: +49 202 946 7726 200',
  },
  {
    german: 'E-Mail: datenschutz.schneider-electric@uimc.de',
    targetLanguage: 'E-mail: datenschutz.schneider-electric@uimc.de',
  },
];

const DataPrivacyIt: React.FC = (props) => {
  return (
    <div className={styles.container}>
      <BiLingualTable columns={columns} dataSource={privacyData} />
    </div>
  );
};

export default DataPrivacyIt;
