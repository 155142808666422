import React from 'react';
import { ColumnsType } from 'antd/lib/table';
import BiLingualTable, {
  IBiLingualData,
  rendrerFn,
} from 'components/ui/BiLingualTable/BiLingualTable';
import styles from './DataPrivacy.module.sass';

const columns: ColumnsType<IBiLingualData> = [
  {
    key: 'german',
    title: 'GREENDatenschutzhinweise Tendergy',
    dataIndex: 'german',
    render: rendrerFn,
  },
  {
    key: 'targetLanguage',
    title: 'Politique de confidentialité des données de Tendergy',
    dataIndex: 'targetLanguage',
    render: rendrerFn,
  },
];

const privacyData: IBiLingualData[] = [
  {
    german: 'Verantwortlicher:',
    targetLanguage: 'Entité responsable:',
    bold: true,
  },
  {
    german: 'Schneider Electric GmbH',
    targetLanguage: 'Schneider Electric GmbH',
  },
  {
    german: 'EUREF-Campus 12-13,',
    targetLanguage: 'EUREF-Campus 12-13,',
  },
  {
    german: 'Torgauer Straße 12-15,',
    targetLanguage: 'Torgauer Straße 12-15,',
  },
  {
    german: '10829 Berlin',
    targetLanguage: '10829 Berlin',
  },
  {
    german: 'Deutschland',
    targetLanguage: 'Allemagne',
  },
  {
    german: 'Telefon: +49 30 23 88 84 - 240',
    targetLanguage: 'Téléphone : +49 30 23 88 84 – 240',
  },
  {
    german: 'E-Mail: info@inno2fleet.com',
    targetLanguage: 'Courriel : info@inno2fleet.com',
  },
  {
    german:
      'Wir nehmen den Schutz Ihrer persönlichen Daten sehr ernst. So behandeln wir Ihre personenbezogenen Daten selbstverständlich stets entsprechend der gesetzlichen Datenschutzvorschriften. Wir haben einen fachkundigen und zuverlässigen externen Datenschutzbeauftragten bestellt.',
    targetLanguage:
      'Nous prenons très au sérieux la protection de vos données personnelles. Cela signifie que nous traiterons bien entendu toujours vos données personnelles conformément aux dispositions légales en matière de protection des données. Nous avons désigné un délégué à la protection des données externe, spécialisé et fiable.',
  },
  {
    german:
      'Die externe Datenschutzbeauftragung wird von der UIMC Dr. Voßbein GmbH & Co KG wahrgenommen (<a href="https://www.uimc.de/datenschutz">https://www.uimc.de/datenschutz</a>), mit Dr. Jörn Voßbein als extern bestelltem Datenschutzbeauftragten.',
    targetLanguage:
      'Le contrôle externe de la protection des données est assuré par UIMC Dr. Voßbein GmbH & Co KG (<a href="https://www.uimc.de/datenschutz">https://www.uimc.de/datenschutz</a>), avec le Dr Jörn Voßbein comme délégué à la protection des données nommé en externe.',
  },
  {
    german:
      'Nachfolgend möchten wir Sie über die Verarbeitung von personenbezogenen Daten informieren.',
    targetLanguage:
      'Nous souhaitons vous informer ci-dessous du traitement des données personnelles.',
  },
  {
    german: '1. Ihre Registrierung als Kunde/Endverbraucher',
    targetLanguage: '1. Votre inscription en tant que client/utilisateur final',
    subSection: true,
  },
  {
    german: 'Erhebung und Verarbeitung persönlicher Daten',
    targetLanguage: 'Collecte et traitement des données personnelles',
  },
  {
    german: 'Anlage eines Nutzeraccounts',
    targetLanguage: 'Créer un compte utilisateur',
    bold: true,
  },
  {
    german:
      'Wir verarbeiten im Rahmen Ihrer ersten Anmeldung und unserer Kontaktaufnahme mit Ihnen Ihre E-Mail-Adresse, Ihren Vor- und Zunamen, ggf. auch Ihren Titel sowie Ihre Telefonnummer. Wir legen für Sie danach einen Nutzeraccount im Rahmen eines Vertragsverhältnisses gemäß Art. 6 Abs 1 lit b DSGVO an.',
    targetLanguage:
      "Dans le cadre de votre inscription initiale et de notre prise de contact avec vous, nous traitons votre adresse e-mail, votre nom et prénom, votre civilité le cas échéant et votre numéro de téléphone. Nous créons ensuite pour vous un compte utilisateur dans le cadre d'une relation contractuelle conformément à l'article 6(1)(b) du RGPD.",
  },
  {
    german: 'Angebotsanfrage/-einholung',
    targetLanguage: 'Demande de devis/Approvisionnement',
    bold: true,
  },
  {
    german:
      'Im Rahmen der Angebotsvorbereitung, Angebotslegung und Vermittlung des Produktkaufs bzw. der Produktinstallation werden außerdem noch erforderliche Angaben erfragt, wie z. B. Anschrift, Beschreibung der örtlichen Gegebenheiten inkl. der von Ihnen hochgeladenen Fotos. Sollten Sie unser Angebot annehmen, verarbeiten wir Ihre Daten aufgrund des bestehenden Vertragsverhältnisses gemäß Art. 6 Abs. 1 lit b DSGVO.',
    targetLanguage:
      "Dans le cadre de la préparation et de la soumission de l'offre et de la conclusion de l'achat, nous vous demanderons d'autres informations obligatoires, telles que l'adresse ou la description des conditions du site, y compris les photos que vous avez téléchargées. Si vous acceptez notre offre, nous traiterons vos données sur la base de la relation contractuelle existante conformément à l'article 6, paragraphe 1, point b) du RGPD.",
  },
  {
    german:
      'Ihre Angaben zu den räumlichen Gegebenheiten verarbeiten wir, um daraus eine zeitliche Aufwandschätzung und die damit zusammenhängende finanzielle Kostenschätzung für unser Angebot vornehmen zu können. Wir verwenden diese als Berechnungsgrundlage für einen marktüblichen Angebotspreis. Die Angaben verarbeiten wir aufgrund unseres berechtigten Interesses nach Art. 6 Abs. 1 lit f DSGVO. Zusätzlich ordnen wir aufgrund unseres berechtigten Interesses der optimalen Gestaltung eines Marktgleichgewichts auch Angebotsanfragen und durchgeführte Installationen räumlich kumuliert einem Bezirk und einem Land zu. Hierdurch wollen wir sicherstellen, dass wir unser Angebot an Kunden und Dienstleistern auch flächendeckend in Deutschland, Österreich und Schweiz bestmöglich anbieten können.',
    targetLanguage:
      "Nous traitons les données relatives aux conditions sur place afin de pouvoir estimer le temps nécessaire et les coûts financiers associés à notre offre. Nous nous en servons comme base pour calculer une offre conforme aux conditions du marché. Nous traitons les informations sur la base de notre intérêt légitime conformément à l'article 6, paragraphe 1, point f) du RGPD. En outre, en raison de notre intérêt légitime à structurer de manière optimale l'équilibre du marché, nous regroupons également les demandes de devis et les installations réalisées au niveau spatial et les attribuons à un district et à un pays. Nous voulons ainsi garantir que nous pouvons également proposer notre gamme de services de la meilleure façon possible aux clients et aux prestataires de services dans toute l'Allemagne, l'Autriche et la Suisse.",
  },
  {
    german:
      'Zum Abschluss der Angebotslegung und späteren Produktinstallation werden Sie gebeten, Ihre freiwillige Be-wertung zu übermitteln, was in Form der Vergabe von Sternen erfolgt. Ihre zusätzliche Bewertung im Freitext-Feld ist freiwillig und hilft uns zur Qualitätssicherung unseres Angebots weiter. Diese Bewertungen sind nicht auf Ihre Person beziehbar.',
    targetLanguage:
      "Il vous sera demandé de soumettre une évaluation volontaire par étoiles à la fin du processus d'appel d'offres et de l'installation du produit qui s'ensuit. Vous pouvez également soumettre une évaluation supplémentaire dans la zone de texte. Cette évaluation est volontaire et nous aide à garantir la qualité de notre service. Ces évaluations ne peuvent pas vous être attribuées personnellement.",
  },
  {
    german: 'Weitergabe der Daten',
    targetLanguage: 'Diffusion des données',
    bold: true,
  },
  {
    german:
      'Sofern Sie sich für eine Angebotslegung entscheiden, dass Sie von einem Installateur ein Angebot erhalten möchten, werden wir Ihre Kontaktdaten sowie die oben genannten Angaben zur Beschreibung der örtlichen Gegebenheiten an einen von uns ins Vertrauen gezogenen Installateur weiterleiten, der sich zwecks der weiteren Angebotsausgestaltung und Terminabsprache mit Ihnen in Verbindung setzt.',
    targetLanguage:
      "Si vous décidez de recevoir un devis d'un installateur, nous transmettrons vos coordonnées ainsi que les informations mentionnées ci-dessus décrivant les conditions du site à un installateur de notre confiance, qui vous contactera pour structurer davantage le devis et fixer un rendez-vous.",
  },
  {
    german:
      'Im weiteren Verlauf der Produktinstallation ist es erforderlich, dass in Teilen Ihre Daten im Falle der Beauftragung des Installateurs an den zuständigen Netzbetreiber weiteregegeben werden. Dies erfolgt auf Grund der Meldepflicht aus §19 Niederspannungsanschlussverordnung (NAV).',
    targetLanguage:
      "Au cours de l'installation du produit, certaines de vos données doivent être transmises à l'opérateur de réseau responsable en cas de mandat de l'installateur. Cela est dû à l'obligation de déclaration découlant de l'article 19 de l'ordonnance sur les raccordements basse tension.",
  },
  {
    german:
      'Im Rahmen der Auswertung von Angebotsanfragen nehmen wir Dienstleister in Anspruch, die nach unserer Weisung im Auftrag handeln und die Auswertung vornehmen. Diese Dienstleister, wie im Punkt 3 aufgeführt, haben Ihren Sitz in der EU bzw. dem EWR bzw. verfügen über ein angemessenes Datenschutzniveau (durch den Abschluss von Standarddatenklauseln).',
    targetLanguage:
      "Lors de l'évaluation des demandes de devis, nous faisons appel à des prestataires de services qui agissent en notre nom et effectuent l'évaluation selon nos instructions. Ces prestataires de services, comme indiqué au point 3, sont basés dans l'UE ou l'EEE ou disposent d'un niveau de protection des données adéquat (grâce à la conclusion de clauses types de protection des données).",
  },
  {
    german: '2. Ihre Registrierung als Installateur',
    targetLanguage: "2. Votre inscription en tant qu'installateur",
    subSection: true,
  },
  {
    german: 'Erhebung und Verarbeitung persönlicher Daten',
    targetLanguage: 'Collecte et traitement des données personnelles',
    bold: true,
  },
  {
    german: 'Anlage eines Nutzeraccounts',
    targetLanguage: 'Créer un compte utilisateur',
    bold: true,
  },
  {
    german:
      'Wir verarbeiten im Rahmen Ihrer Anmeldung und unserer Kontaktaufnahme mit Ihnen Ihre E-Mail-Adresse, Ihren Vor- und Zunamen, ggf. auch Ihren Titel, Ihre Mobil-Telefonnummer, Faxnummer, den Namens eines Ansprechpartners, Ihren Firmennamen, die Firmenanschrift sowie Ihre UST-ID. Wir legen für Sie anschließend einen Nutzeraccount als Installateur, im Rahmen eines Vertragsverhältnisses gemäß Art. 6 Abs 1 lit b DSGVO, an.',
    targetLanguage:
      "Dans le cadre de votre inscription et de notre prise de contact avec vous, nous traitons votre adresse e-mail, votre prénom et votre nom, votre civilité le cas échéant, votre numéro de téléphone portable, votre numéro de fax, le nom d'une personne de contact, le nom de votre entreprise, l'adresse de votre entreprise et votre numéro de TVA. Nous créons ensuite pour vous un compte utilisateur en tant qu'installateur dans le cadre d'une relation contractuelle conformément à l'article 6, paragraphe 1, point b) du RGPD.",
  },
  {
    german:
      'Die Kopie Ihres Installateurausweises sowie die Nummer Ihres Ausweises verarbeiten wir gemäß der rechtlichen Verpflichtung Art. 6 Abs 1 lit c DSGVO.',
    targetLanguage:
      "Nous traiterons la copie de votre carte d'installateur et de votre numéro de carte d'identité conformément à l'obligation légale prévue à l'article 6(1)(c) du RGPD.",
  },
  {
    german:
      'Über die Platform tendergy haben Sie die Gelegenheit, mit Endkunden Kontakt aufzunehmen zwecks einer Angebotslegung und Terminabsprache sowie der Abwicklung einer Projektinstallation inkl. Der Rechnungslegung an den Kunden.',
    targetLanguage:
      'La plateforme Tendergy vous permet de contacter les clients finaux pour soumettre un devis et fixer un rendez-vous, ainsi que de traiter les installations des projets, y compris la facturation au client.',
  },
  {
    german: 'Weitergabe der Daten',
    targetLanguage: 'Diffusion des données',
    bold: true,
  },
  {
    german:
      'Zum Abschluss eines Auftrags hat der Endkunde die Möglichkeit, die vorgenommene Projektinstallation sowie die gesamte Abwicklung zu bewerten, wobei eine Bewertungsskala in Form von Sternen verwendet wird. Da-neben kann der Kunden noch in Freitext-Feldern Bewertungshinweise eingeben. Diese Bewertung können wir, als Schneider Electric, einsehen und verwenden diese zur Qualitätssicherung der, über tendergy, angebotenen Produkte.',
    targetLanguage:
      "Le client aura la possibilité de soumettre une évaluation sous forme d'étoiles pour évaluer l'installation du projet ainsi que l'exécution dans son ensemble à la fin d'une commande. Le client aura également la possibilité d'ajouter des notes d'évaluation dans une zone de texte. En tant que Schneider Electric, nous pourrons voir cette évaluation et l'utiliserons à des fins d'assurance qualité concernant les produits proposés sur tendergy.",
  },
  {
    german:
      '3. Weitere Angaben zur Webanalyse, Cookies, Protokollierung sowie weiteren Details, den Datenschutz betreffend',
    targetLanguage:
      "3. Informations complémentaires sur l'analyse Web, les cookies, la journalisation et d'autres détails concernant la protection des données",
    subSection: true,
  },
  {
    german: 'Weitergabe der Daten für alle Parteien',
    targetLanguage: 'Diffusion des données à toutes les parties',
    bold: true,
  },
  {
    german:
      'Eine Weitergabe an Dritte, zu kommerziellen oder nicht-kommerziellen Zwecken, findet ohne Ihre ausdrückliche Zustimmung grundsätzlich nicht statt. Wir geben Ihre persönlichen Daten nur dann an Dritte weiter, wenn dies gesetzlich zulässig [wie z. B. auf Basis des Artikel 6 DSGVO] und/oder erforderlich ist. Teilweise setzen wir Dienstleister im Rahmen einer Auftragsverarbeitung gemäß Artikel 28 DSGVO ein. Schneider Electric bleibt weiterhin die für die Datenverarbeitung verantwortliche Stelle. Im Folgenden werden die Empfänger im Rahmen der Verarbeitung Ihrer Daten beschrieben.',
    targetLanguage:
      "En principe, la divulgation à des tiers, à des fins commerciales ou non commerciales, n'aura pas lieu sans votre consentement exprès. Nous ne divulguerons vos données personnelles à des tiers que si cela est légalement autorisé [par exemple sur la base de l'article 6 du RGPD] et/ou nécessaire. Dans certains cas, nous faisons appel à des prestataires de services dans le cadre du traitement des commandes conformément à l'article 28 du RGPD. Schneider Electric reste l'entité responsable du traitement des données. Les sections suivantes détaillent les destinataires impliqués dans le traitement de vos données.",
  },
  {
    german: 'Microsoft Cloud Service Azure',
    targetLanguage: 'Microsoft Cloud Service Azure',
    bold: true,
  },
  {
    german:
      'Diese Platform (tendergy) wird auf dem Microsoft Cloud Service Azure betrieben. Ihre Daten werden auf den EU Servern der Microsoft Ireland Operations Ltd, One Microsoft Place, South County Business Park, Leopardstown, Dublin 18, D18 P521, Irland gespeichert. Die Nutzung des Microsoft Cloud Service Azure stellt die fehlerfreie Funktion der Internetplattform tendergy und die Datensicherheit sicher. Ihre Kundendaten werden in den Rechenzentren der Microsoft Cloud Service Azure grundsätzlich durch eine Vielzahl von Technologien und Prozessen geschützt, einschließlich verschiedener Formen der Verschlüsselung. Die von Ihnen zwecks der Bereitstellung der tendergy Platform zur Verfügung gestellten Daten (siehe Erstellung eines Nutzeraccounts) wer-den auf den Servern von Microsoft Cloud Service Azure in der EU unter Einhaltung der DSGVO gespeichert. Die Datenverarbeitung erfolgt auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie können diese Einwilligung jederzeit widerrufen, indem Sie Ihren Nutzeraccount löschen. Die Rechtmäßigkeit der bereits erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt. Die bei uns hinterlegten personenbezogenen Daten werden anschließend automatisch von unseren Servern als auch bei Microsoft Azure nach 90-Tagen Aufbewahrungsfrist gelöscht. Microsoft Cloud Service Azure wird die verarbeiteten Informationen gegebenenfalls an Dritte, z. B. US-Behörden, übertragen, sofern dies gesetzlich vorgeschrieben ist, oder soweit Dritte diese Daten im Auftrag von Microsoft Cloud Service Azure verarbeiten. Weitere Informationen zur Microsoft Azure Cloud finden Sie in der Datenschutzerklärung von Microsoft: <a href="https://www.microsoft.com/de-de/trust-center/privacy">https://www.microsoft.com/de-de/trust-center/privacy</a>',
    targetLanguage:
      "Cette plateforme (tendergy) est exploitée sur le service cloud Microsoft Azure. Vos données sont enregistrées sur les serveurs européens de Microsoft Ireland Operations à One Microsoft Place, South County Business Park, Leopardstown, Dublin 18, D18 P521, Irlande. L’utilisation du service cloud Microsoft Azure garantit un fonctionnement sans faille de la plateforme Internet tendergy et la sécurité des données. Vos données client sont principalement protégées dans les centres de données du service cloud Microsoft Azure par diverses technologies et processus, y compris diverses formes de cryptage. Les données que vous fournissez pour le déploiement de la plateforme tendergy (voir « Créer un compte utilisateur ») sont stockées sur les serveurs du service cloud Microsoft Azure dans l’UE conformément au RGPD. Le traitement des données est basé sur votre consentement (article 6(1)(a) du RGPD). Vous pouvez révoquer ce consentement à tout moment en supprimant votre compte utilisateur. La légalité des opérations de traitement des données qui ont déjà eu lieu n’est pas affectée par cette révocation. Les données personnelles que nous stockons seront ensuite automatiquement supprimées de nos serveurs ainsi que de Microsoft Azure après une période de conservation de 90 jours. Microsoft Azure Cloud Service peut transférer les informations traitées à des tiers, par exemple les autorités américaines, si la loi l'exige ou si des tiers traitent ces données pour le compte de Microsoft Azure Cloud Service. Pour plus d'informations sur Microsoft Azure Cloud, veuillez consulter la politique de confidentialité de Microsoft à l'adresse <a href=\"https://www.microsoft.com/de-de/trust-center/privacy\">https://www.microsoft.com/de-de/trust-center/privacy</a>",
  },
  {
    german: 'Vonage',
    targetLanguage: 'Vonage',
    bold: true,
  },
  {
    german:
      'Diese Platform (tendergy) benutzt Vonage, ein Kommunikationsdiestleister für Videochatfunktionen der Vonage Holdings Corp., 23 Main Street, Holmdel, NJ 07733 USA, sofern Sie hierzu Ihre Einwilligung gemäß Art. 6 Abs. 1 lit. a DSGVO erteilt haben. Die Nutzung des Dienstleisters Vonage ist zum Zweck des Video Chats zur Optimie-rung der Kommunikation zwischen Endnutzer*innen und Installateur*innen von tendergy. Die von Ihnen zwecks der verbesserten Kommunikation angegebenen Daten (Anrede, Name & Email-Adresse) werden auf den Servern von Vonage in der EU, unter Einhaltung der DSGVO gespeichert. Die Datenverarbeitung erfolgt auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie können diese Einwilligung jederzeit widerrufen, indem Sie entweder Ihre Cookie-Einstellung verändern oder Ihren Nutzeraccount löschen. Die Rechtmäßigkeit der bereits erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt. Die von Ihnen zum Zwecke des optimalen Kommunikationsangebotes auf der Platform und beim Platformbetreiber hinterlegten Daten werden von Schneider Electric bis zu Ihren veränderten Cookie-Einstellungen oder bis zur Löschung Ihres Nutzeraccounts automatisch sowohl von den Schneider Electric Servern als auch von den Servern von Vonage gelöscht. Näheres ent-nehmen Sie den Informationen zu “Sicherheit und Datenschutz” von Vonage unter <a href="https://www.vonage.com/resources/articles/gdpr-compliance-our-commitment-to-security/">https://www.vonage.com/resources/articles/gdpr-compliance-our-commitment-to-security/</a> und der privacy Policy <a href="https://www.vonage.com/legal/privacy-policy/?icmp=footer_legalpolicy_privacy">https://www.vonage.com/legal/privacy-policy/?icmp=footer_legalpolicy_privacy</a>',
    targetLanguage:
      "Cette plateforme (tendergy) utilise Vonage, un fournisseur de services de communication pour les fonctionnalités de chat vidéo, à condition que vous y ayez consenti conformément à l'article 6(1)(a) du RGPD. Vonage est un produit de Vonage Holdings Corp., 23 Main Street, Holmdel, NJ 07733 USA. Le fournisseur de services est utilisé à des fins de chat vidéo pour optimiser la communication entre les utilisateurs finaux de tendergy et les installateurs. Les données que vous fournissez pour améliorer la communication (titre, nom et adresse e-mail) seront stockées sur les serveurs européens de Vonage, conformément au RGPD. Le traitement des données est basé sur votre consentement (article 6(1)(a) du RGPD). Vous pouvez révoquer ce consentement à tout moment en modifiant vos paramètres de cookies ou en supprimant votre compte utilisateur. La légalité des opérations de traitement des données qui ont déjà eu lieu ne sera pas affectée par cette révocation. Les données que vous avez fournies à la plateforme et à l'opérateur de la plateforme afin d'optimiser les services de communication de la plateforme seront automatiquement supprimées par Schneider Electric des serveurs Schneider Electric et Vonage jusqu'à ce que vos paramètres de cookies soient modifiés ou jusqu'à ce que votre compte utilisateur soit supprimé. Pour plus d'informations, veuillez vous référer aux informations sur la « Sécurité et la protection des données » de Vonage à l'adresse <a href=\"https://www.vonage.com/resources/articles/gdpr-compliance-our-commitment-to-security/\">https://www.vonage.com/resources/articles/gdpr-compliance-our-commitment-to-security/</a> et à la politique de confidentialité <a href=\"https://www.vonage.com/legal/privacy-policy/?icmp=footer_legalpolicy_privacy\">https://www.vonage.com/legal/privacy-policy/?icmp=footer_legalpolicy_privacy</a>.",
  },
  {
    german: 'Cookies',
    targetLanguage: 'Cookies',
    bold: true,
  },
  {
    german:
      'Diese Seite verwendet „Cookies“. Cookies sind Textdateien, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung der Internetpräsenz ermöglicht und Sie bei Ihrem nächsten Besuch automatisch wiedererkennen. Sie können die Installation der Cookies durch eine entsprechende Einstellung des Cookie Ban-ners oder Ihres Browsers verhindern. Sie haben auch die Möglichkeit, Ihre erteilte Einwilligung jederzeit im Cookie Banner bzw. den Cookie Einstellungen zu widerrufen in dem Sie auf das Cookie-Symbol (Keks-Icon) clicken und die gewünschten Einstellungen anpassen. Dies kann ggf. dazu führen, dass Sie nicht alle Angebote vollumfänglich nutzen können.',
    targetLanguage:
      "Ce site utilise des « cookies ». Les cookies sont des fichiers texte qui sont enregistrés sur votre ordinateur et permettent d'analyser l'utilisation du site Internet et de vous reconnaître automatiquement lors de votre prochaine visite. Vous pouvez empêcher l'installation de cookies en paramétrant la bannière de cookies ou votre navigateur en conséquence. Vous pouvez également révoquer votre consentement à tout moment dans la bannière de cookies ou dans les paramètres des cookies en cliquant sur le symbole de cookie (icône de biscuit) et en effectuant les réglages souhaités. Cela peut avoir pour conséquence que vous ne puissiez pas utiliser pleinement tous les services.",
  },
  {
    german:
      'Auf unserer Seite nutzen wir verschiedene Plugins anderer Dienstleister, über die wir Sie nachfolgend informieren möchten.',
    targetLanguage:
      "Nous utilisons sur notre site divers plugins d'autres fournisseurs de services, dont nous souhaitons vous informer ci-dessous.",
  },
  {
    german: 'Protokollierung',
    targetLanguage: 'Enregistrement',
    bold: true,
  },
  {
    german:
      'Bei jedem Zugriff auf die Internetpräsenz werden Protokolle zu statistischen Zwecken erstellt und verarbeitet, wobei der einzelne Benutzer hierbei anonym bleibt:',
    targetLanguage:
      "Des journaux sont créés et traités à des fins statistiques à chaque fois que le site Web est consulté, l'utilisateur individuel restant anonyme :",
  },
  {
    german: '1.	Referrer (Seite, von deren Link Sie zu dieser Internetpräsenz gelangt sind)',
    targetLanguage:
      '1. Référent (lien de la page à partir de laquelle vous avez accédé à ce site Web)',
  },
  {
    german: '2.	Suchbegriffe (bei Suchmaschinen als Referrer)',
    targetLanguage:
      '2. Termes de recherche (dans les cas où les moteurs de recherche sont des référents)',
  },
  {
    german: '3.	IP wird zur Bestimmung des Herkunftslands und des Providers ausgewertet',
    targetLanguage: "3. L'IP est analysée pour déterminer le pays d'origine et le fournisseur.",
  },
  {
    german: '4.	Browser, Betriebssystem, installierte Plug-ins und Bildschirmauflösung',
    targetLanguage:
      "4. Navigateur, système d'exploitation, plug-ins installés et résolution d'écran",
  },
  {
    german: '5.	Aufenthaltszeit auf den Seiten',
    targetLanguage: '5. Temps passé sur les pages',
  },
  {
    german: '6.	Art des Endgerätes',
    targetLanguage: '6. Type de terminal',
  },
  {
    german:
      'Die genannten Daten werden auf Basis unseres berechtigten Interesses gemäß Art. 6 Abs. 1 lit. f DSGVO durch uns zu folgenden Zwecken verarbeitet:',
    targetLanguage:
      "Les données susmentionnées sont traitées par nos soins sur la base de notre intérêt légitime conformément à l'art. 6 (1) (f) du RGPD aux fins suivantes :",
  },
  {
    german: '1.	Gewährleistung eines reibungslosen Verbindungsaufbaus der Internetpräsenz,',
    targetLanguage: '1. Garantir une connexion fluide au site Web',
  },
  {
    german: '2.	Gewährleistung einer komfortablen Nutzung unserer Internetpräsenz,',
    targetLanguage: "2. Garantir la facilité d'utilisation de notre site Internet",
  },
  {
    german: '3.	Auswertung der Systemsicherheit und -stabilität sowie',
    targetLanguage: '3. Évaluation de la sécurité et de la stabilité du système ; et',
  },
  {
    german: '4.	zu weiteren administrativen Zwecken.',
    targetLanguage: '4. Autres fins administratives',
  },
  {
    german:
      'Wir behalten uns vor, diese Daten nachträglich zu prüfen, wenn uns konkrete Anhaltspunkte für eine rechtswidrige Nutzung bekannt werden. Die Daten werden umgehend gelöscht, wenn sie zur Zweckerreichung nicht mehr erforderlich sind, spätestens jedoch nach sechs Monaten.',
    targetLanguage:
      "Nous nous réservons le droit de vérifier ces données ultérieurement si nous avons connaissance d'indices concrets d'utilisation illicite. Les données seront immédiatement supprimées si elles ne sont plus nécessaires à la réalisation d'un objectif quelconque, mais au plus tard après un délai de six mois.",
  },
  {
    german: 'Aufbewahrung und Löschung der Daten',
    targetLanguage: 'Stockage et suppression des données',
    bold: true,
  },
  {
    german:
      'Ihre Daten werden solange aufbewahrt, wie dies für die jeweiligen o. g. Zwecke erforderlich ist. Die Daten werden spätestens nach Beendigung des Vertragsverhältnisses und nach Ablauf der gesetzlichen Aufbewahrungsfristen des Zivil-, Handels- und Steuerrechts gelöscht. In der Regel beträgt die Löschfrist bei Erteilung eines Auftrags und späterer Rechnungsstellung 10 Jahre.',
    targetLanguage:
      "Vos données seront conservées aussi longtemps que nécessaire pour atteindre les finalités mentionnées ci-dessus. Les données seront supprimées au plus tard à la fin de la relation contractuelle et à l'expiration des délais de conservation légaux en matière civile, commerciale et fiscale. Le délai de suppression est généralement de 10 ans dans les cas où une commande est passée et ensuite facturée.",
  },
  {
    german:
      'Sie können Ihren Nutzeraccount jederzeit selbstständig löschen, wobei im Falle eines bereits erteilten Auftrags und späterer Rechnungsstellung die Daten gemäß Art. 18 DSGVO für die Verarbeitung eingeschränkt werden und erst nach Ablauf der gesetzlichen Fristen aus Zivil-, Handels- und Steuerrecht gelöscht werden.',
    targetLanguage:
      "Vous pouvez supprimer vous-même votre compte utilisateur à tout moment, mais si une commande a déjà été passée et que des factures doivent être émises ultérieurement, les données seront limitées au traitement conformément à l'art. 18 du RGPD et ne seront supprimées qu'après l'expiration des délais légaux du droit civil, commercial et fiscal.",
  },
  {
    german: 'Haftung für eigene Inhalte',
    targetLanguage: 'Responsabilité pour son propre contenu',
    bold: true,
  },
  {
    german:
      'Die Inhalte dieser Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich.',
    targetLanguage:
      "Les contenus de ces pages ont été créés avec le plus grand soin. Nous ne pouvons toutefois pas garantir l'exactitude, l'exhaustivité et l'actualité des contenus. En tant que prestataire de services, nous sommes responsables de nos propres contenus sur ces pages conformément aux lois générales.",
  },
  {
    german: 'Haftung Inhalte fremder Anbieter',
    targetLanguage: 'Responsabilité pour le contenu des fournisseurs tiers',
    bold: true,
  },
  {
    german:
      'Von diesen eigenen Inhalten sind Querverweise ("Links") auf die von anderen Anbietern bereitgehaltenen In-halte zu unterscheiden. Auf deren Inhalt haben wir keinen Einfluss; für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich.',
    targetLanguage:
      "Ces contenus doivent être distingués des renvois (« liens ») vers des contenus d'autres fournisseurs. Nous n'avons aucune influence sur leur contenu ; le fournisseur ou l'exploitant respectif des pages est toujours responsable du contenu des pages liées.",
  },
  {
    german: 'Rechte der Betroffenen',
    targetLanguage: 'Droits des parties concernées',
    bold: true,
  },
  {
    german:
      'Wir informieren Sie hiermit darüber, dass Sie gemäß Artikel 15 ff. DSGVO uns gegenüber unter den dort defi-nierten Voraussetzungen das Recht auf Auskunft über die betreffenden personenbezogenen Daten sowie auf Berichtigung oder Löschung oder auf Einschränkung der Verarbeitung oder eines Widerspruchsrechts gegen die Verarbeitung sowie des Rechts auf Datenübertragbarkeit haben. Auch haben Sie gemäß Artikel 77 DSGVO das Recht der Beschwerde bei einer Datenschutz-Aufsichtsbehörde, wenn Sie der Ansicht sind, dass die Verarbeitung der Sie betreffenden personenbezogenen Daten gegen diese Verordnung verstößt. Wenn die Verarbeitung auf Artikel 6 Absatz 1 Buchstabe a DSGVO oder Artikel 9 Absatz 2 Buchstabe a DSGVO beruht (Einwilligung), haben Sie ferner das Recht, die Einwilligung jederzeit zu widerrufen, ohne dass die Rechtmäßigkeit der auf-grund der Einwilligung bis zum Widerruf erfolgten Verarbeitung berührt wird.',
    targetLanguage:
      "Nous vous informons que, conformément à l'article 15 et suivants du RGPD, vous disposez d'un droit d'accès aux données personnelles concernées, d'un droit de rectification, d'effacement ou de limitation du traitement ou d'un droit d'opposition au traitement, ainsi que d'un droit à la portabilité des données, sous réserve des conditions qui y sont définies. Vous avez également le droit d'introduire une réclamation auprès d'une autorité de contrôle de la protection des données conformément à l'article 77 du RGPD si vous estimez que le traitement des données personnelles vous concernant viole ce règlement. Si le traitement est basé sur l'article 6, paragraphe 1, point a), du RGPD ou sur l'article 9, paragraphe 2, point a), du RGPD (consentement), vous avez également le droit de révoquer votre consentement à tout moment sans que cela n'affecte la licéité du traitement effectué sur la base de votre consentement jusqu'au moment du retrait.",
  },
  {
    german: 'Änderung unserer Datenschutzbestimmungen',
    targetLanguage: 'Modifications de notre politique de protection des données',
    bold: true,
  },
  {
    german:
      'Wir behalten uns vor, diese Datenschutzerklärung gelegentlich anzupassen, damit sie stets den aktuellen rechtlichen Anforderungen entspricht oder um Änderungen unserer Leistungen in der Datenschutzerklärung umzusetzen, z. B. bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt dann die neue Datenschutzerklärung.',
    targetLanguage:
      "Nous nous réservons le droit de modifier de temps à autre la présente politique de confidentialité afin de garantir qu'elle soit toujours conforme aux exigences légales en vigueur ou pour mettre en œuvre des modifications de nos services dans la politique de confidentialité, par exemple lors de l'introduction de nouveaux services. La nouvelle politique de confidentialité s'appliquera alors à votre prochaine visite.",
  },
  {
    german: 'Kontakt zum Datenschutzbeauftragten',
    targetLanguage: 'Contacter le délégué à la protection des données',
    bold: true,
  },
  {
    german:
      'Wenn Sie Fragen hinsichtlich der Verarbeitung Ihrer persönlichen Daten haben, können Sie sich direkt an unseren Datenschutzbeauftragten wenden, der mit seinem Team auch im Falle von Auskunftsersuchen, Anträgen oder Beschwerden zur Verfügung steht.',
    targetLanguage:
      "Si vous avez des questions concernant le traitement de vos données personnelles, vous pouvez contacter directement notre délégué à la protection des données, qui, avec son équipe, est également disponible pour les demandes d'informations, les candidatures ou les réclamations.",
  },
  {
    german: 'Der externe Datenschutzbeauftragte ist erreichbar unter:',
    targetLanguage:
      "Le délégué externe à la protection des données est disponible à l'adresse suivante :",
    bold: true,
  },
  {
    german: 'Dr. Jörn Voßbein, externer Datenschutzbeauftragter,',
    targetLanguage: 'Dr. Jörn Voßbein, délégué externe à la protection des données,',
  },
  {
    german: 'UIMC Dr. Voßbein GmbH & Co KG',
    targetLanguage: 'UIMC Dr. Voßbein GmbH & Co KG',
  },
  {
    german: 'Otto-Hausmann-Ring 113,',
    targetLanguage: 'Otto-Hausmann-Ring 113,',
  },
  {
    german: '42115 Wuppertal',
    targetLanguage: '42115 Wuppertal',
  },
  {
    german: 'Telefon: +49 202 946 7726 200',
    targetLanguage: 'Téléphone : +49 202 946 7726 200',
  },
  {
    german: 'E-Mail: datenschutz.schneider-electric@uimc.de',
    targetLanguage: 'Courriel : datenschutz.schneider-electric@uimc.de',
  },
];

const DataPrivacyFr: React.FC = (props) => {
  return (
    <div className={styles.container}>
      <BiLingualTable columns={columns} dataSource={privacyData} />
    </div>
  );
};

export default DataPrivacyFr;
